<!-- ============================================================== -->
<!-- Main wrapper - style you can find in pages.scss -->
<!-- ============================================================== -->
<div class="main-container"> 
    <!-- ============================================================== -->
    <!-- Topbar - style you can find in header.scss -->
    <!-- ============================================================== -->
    <mat-toolbar style="height: 120px; background-color: 
    #61a1e9;"  class="topbar telative">
        <!-- ============================================================== -->
        <!-- Logo - style you can find in header.scss -->
        <!-- ============================================================== -->
        <div class="navbar-header">
            <a class="navbar-brand" >
                <!-- Logo icon --><b>
                    <!--You can put here icon as well // <i class="wi wi-sunset"></i> //-->
                    <!-- Dark Logo icon -->
                    <!-- <img src="assets/images/logo-icon.png" alt="homepage" class="dark-logo"> -->
                    <!-- Light Logo icon -->
                    <!-- <img  src="assets/images/l&T/logo.jpeg" alt="homepage" class="light-logo"> -->
                </b>
                <!--End Logo icon -->
                <!-- Logo text -->
                <span fxShow="false" fxShow.gt-xs>
                 <!-- dark Logo text -->
                 <!-- <img src="assets/images/logo-text.png" alt="homepage" class="dark-logo"> -->
                 <!-- Light Logo text --> 
                 <a href="/zone">
                 <img style="height: 100px;" src="assets/images/l&T/logonew.jpeg" class="light-logo" alt="homepage">
                </a>     </span> &nbsp;&nbsp; <span fxShow="false" fxShow.gt-xs><button mat-icon-button (click)="snav.toggle()" value="sidebarclosed">
                    <mat-icon style="color: #FFF;">menu</mat-icon>
                </button> </span> </a>
        </div>
        <!-- ============================================================== -->
        <!-- sidebar toggle -->
        <!-- ============================================================== -->
        
        <div class="text-center">
          <h1 class="heading">Haryana Vidyut Prasaran Nigam Limited</h1>

        </div>
        <button id="menuButton" mat-icon-button (click)="snav.toggle()" value="sidebarclosed">
            <mat-icon style="color: #FFF;">menu</mat-icon>
          </button>
        <span fxFlex></span>
        <!-- ============================================================== -->
        <!-- app header component - style you can find in header.scss / header.component.ts-->
        <!-- ============================================================== -->
        <app-header></app-header>
    </mat-toolbar>
    <!-- ============================================================== -->
    <!-- End Topbar - style you can find in pages.scss -->
    <!-- ============================================================== -->
    <mat-sidenav-container  class="example-sidenav-container" [style.marginTop.px]="mobileQuery.matches ? 0 : 0">
        <!-- ============================================================== -->
        <!-- Sidebar - style you can find in sidebar.scss -->
        <!-- ============================================================== -->
        <mat-sidenav style="width: auto;" #snav id="snav" class="dark-sidebar pl-xs" [mode]="mobileQuery.matches ? 'side' : 'over'" fixedTopGap="0" [opened]="mobileQuery.matches" [disableClose]="mobileQuery.matches"  >
            
                <app-sidebar ></app-sidebar>
               
            
        </mat-sidenav>
        <!-- ============================================================== -->
        <!-- Sidebar - style you can find in sidebar.scss -->
        <!-- ============================================================== -->
        
        <!-- ============================================================== -->
        <!-- Page container - style you can find in pages.scss -->
        <!-- ============================================================== -->
        <mat-sidenav-content class="page-wrapper">
                 
                <div class="page-content">
                   
                        <router-outlet><app-spinner></app-spinner></router-outlet>
                    
                </div>    
               
        </mat-sidenav-content>
        <!-- ============================================================== -->
        <!-- Page container - style you can find in pages.scss -->
        <!-- ============================================================== -->
    </mat-sidenav-container>
</div>

<style>
 .heading {
  font-size: 3.0rem;
  text-align: center;
  color: #FFF;
  letter-spacing: 1px;
}

/* Responsive styles */
@media (max-width: 768px) {
  .heading {
    font-size: 1.1rem;
    padding-top: 5%;
  }
}

@media (max-width: 480px) {
  .heading {
    font-size: 1.5rem;
    padding-top: 10%;
  }
}


    /* Default button display */
#menuButton {
  display: none;
}

/* Display button for screens up to 768px wide */
@media (max-width: 600px) {
  #menuButton {
    display: block;
  }
}

/* Display button for screens up to 480px wide */
@media (max-width: 480px) {
  #menuButton {
    display: block;
  }
}
    </style>
    