import { Component, TemplateRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { AbstractControl, FormBuilder, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { DashboardApiService } from 'src/app/services/auth/api services/dashboard-api.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: []
})
export class AppHeaderComponent {

  name: any;
  role: any;
  userIcon: any;
  PasswordForm!: FormGroup
  isPasswordMismatch: boolean = false
  userId: any;
  matDialogReference: any;

  showPassword: boolean = false;

  togglePasswordVisibility() {
    this.showPassword = !this.showPassword;
  }
  constructor(private router: Router, private dialog: MatDialog, private formbuilder: FormBuilder, private api: DashboardApiService,private toster:ToastrService) {
    this.PasswordForm = this.formbuilder.group({
      currentpassword: ['', Validators.required],
      newpassword: ['', Validators.required],
      confirmpassword: ['', Validators.required],
    }, {
      validator: this.passwordMatchValidator('newpassword', 'confirmpassword'),
    });
  }
  @ViewChild('myDialog') myDialogTemplate: TemplateRef<any>;
  dialogRef: MatDialogRef<any>;

  openDialog() {
  // openDialog(templateRef: TemplateRef<any>) {
      // this.dialog.open(templateRef);
     this.dialogRef = this.dialog.open(this.myDialogTemplate);

    this.dialogRef.afterClosed().subscribe(() => {
      this.PasswordForm.reset();
    });
  }
  


  ngOnInit(): void {
    this.name = localStorage.getItem('firstname')
    this.role = sessionStorage.getItem('role_title')
    this.userIcon = this.name.slice(0, 1)
    // console.log(  this.name.slice(0,1));



  }
  passwordMatchValidator(controlName: string, matchingControlName: string) {
    return (formGroup: FormGroup) => {
      const control = formGroup.controls[controlName];
      const matchingControl = formGroup.controls[matchingControlName];
      if (
        matchingControl.errors &&
        !matchingControl.errors.confirmedValidator
      ) {
        return;
      }
      if (control.value !== matchingControl.value) {
        matchingControl.setErrors({ confirmedValidator: true });
      } else {
        matchingControl.setErrors(null);
      }
    };

  }

  ChangePassword(data: any, templateRef: TemplateRef<any>) {
    localStorage.getItem('userid');
    this.userId = localStorage.getItem('userid');
    if (this.PasswordForm.invalid) {
      // Show password mismatch error if the form is invalid
      this.PasswordForm.get('confirmpassword')?.setErrors({ mismatch: true });
      return;
    }
    var payload = {
      'userid': this.userId,
      'currentpassword': data.currentpassword,
      'newpassword': data.newpassword
    }

    this.api.changePassword(payload).subscribe((result: any) => {
      if (result.code === 200) {
        this.dialog.closeAll()
        this.PasswordForm.reset()
        this.toster.success(result.message)
        localStorage.clear();
        sessionStorage.clear();
        this.router.navigate([''])
      }else{
      }
    })


  }


  logout() {
    localStorage.clear();
    sessionStorage.clear();
    this.router.navigate([""]);
  }

}
