<div class="total">
<div class="container">
  <mat-card>
    <mat-card-header>
      <div ><img class="center" src="assets/images/l&T/logonew.jpeg"></div>
      <!-- <mat-card-title style="text-align: center;">Login</mat-card-title> -->
    </mat-card-header>
    <mat-card-content>
      <form [formGroup]="loginForm" (keyup.enter)="login(loginForm.value)">
        <mat-form-field>
          <mat-label>User ID</mat-label>
          <input matInput formControlName="useremail" type="text" placeholder="Enter your User ID">
          <mat-error *ngIf="loginForm.controls['useremail'].hasError('required')">User ID is Required</mat-error>
          <mat-error *ngIf="loginForm.controls['useremail'].hasError('pattern')">Enter a Vaild User ID</mat-error>
          </mat-form-field>
          <mat-form-field>
            <mat-label>Password</mat-label>
            <input matInput formControlName="password" [type]="hidePassword ? 'password' : 'text'" placeholder="Enter your password">
            <button mat-icon-button matSuffix >
              <mat-icon (click)="togglePasswordVisibility()">{{ hidePassword ? 'visibility_off' : 'visibility' }}</mat-icon>
            </button>
            <mat-error *ngIf="loginForm.controls['password'].hasError('required')">Password is required</mat-error>
          </mat-form-field>
        <button mat-raised-button color="primary" [disabled]="loginButtonDisabled"  (click)="login(this.loginForm.value)" >Log In</button>
        <!-- <button mat-raised-button color="primary"(click)="changepassword()">Change Password</button> -->
      </form>
    </mat-card-content>
  </mat-card>
</div>
</div>

