import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
// import { BehaviorSubject, Observable } from 'rxjs';
import data from 'src/assets/json/api.json'


@Injectable({
  providedIn: 'root'
})
export class DashboardApiService {
  lov() {
    throw new Error('Method not implemented.');
  }

  baseUrl = data.api.baseUrl;
  lovdata: any;
  constructor(private http: HttpClient) { }



  changePassword(postData: any) {
    var accesstoken: any;
    accesstoken = sessionStorage.getItem("token")
    var tokenheaders = new HttpHeaders({
      Authorization: "Bearer " + accesstoken
    });
    return this.http.post(this.baseUrl + data.api.dashoard.login.changepassword, postData, { headers: tokenheaders });
  }
  fetchStats(substationid:any, transmissionlineid:any){
    var accesstoken: any;
    accesstoken = sessionStorage.getItem("token")
    var tokenheaders = new HttpHeaders({
      Authorization: "Bearer " + accesstoken
    });
    return this.http.get<any>(this.baseUrl + data.api.dashoard.analysisdashboards.analysisdashboard+ '?transmissionlineid='+transmissionlineid+"&substationid="+substationid, { headers: tokenheaders });
  }
  addEmployee(postData: any) {
    var accesstoken: any;
    accesstoken = sessionStorage.getItem("token")
    var tokenheaders = new HttpHeaders({
      Authorization: "Bearer " + accesstoken
    });
    return this.http.post(this.baseUrl + data.api.dashoard.employee.employee, postData, { headers: tokenheaders });
  }

  getEmployee(page: any, limit: any) {
    var accesstoken: any;
    accesstoken = sessionStorage.getItem("token")
    var tokenheaders = new HttpHeaders({
      Authorization: "Bearer " + accesstoken
    });
    return this.http.get<any>(this.baseUrl + data.api.dashoard.employee.employee+ '?page='+page+"&limit="+limit , { headers: tokenheaders });
  }

  checkTowerNumberExist(transmission_line_id: any,tower_number:any){
    var accesstoken: any;
    accesstoken = sessionStorage.getItem("token")
    var tokenheaders = new HttpHeaders({
      Authorization: "Bearer " + accesstoken
    });
    return this.http.get<any>(this.baseUrl + data.api.dashoard.towers.checkTowerNumberExist+"?transmission_line_id="+transmission_line_id+"&tower_number="+tower_number, { headers: tokenheaders });
  }
  addTowers(postData: any){
    var accesstoken: any;
    accesstoken = sessionStorage.getItem("token")
    var tokenheaders = new HttpHeaders({
      Authorization: "Bearer " + accesstoken
    });
    return this.http.post<any>(this.baseUrl + data.api.dashoard.towers.towers,postData, { headers: tokenheaders });
  }

  collectionAttribute(TOWERS: any){
    var accesstoken: any;
    accesstoken = sessionStorage.getItem("token")
    var tokenheaders = new HttpHeaders({
      Authorization: "Bearer " + accesstoken
    });
    return this.http.get<any>(this.baseUrl+data.api.dashoard.collectionAttribute.collectionAttribute+"?group_type="+TOWERS,{headers:tokenheaders})
  }
  editTowers(putData: any){
    var accesstoken: any;
    accesstoken = sessionStorage.getItem("token")
    var tokenheaders = new HttpHeaders({
      Authorization: "Bearer " + accesstoken
    });
    return this.http.put<any>(this.baseUrl + data.api.dashoard.towers.towers,putData, { headers: tokenheaders });
  }
  deleteTower(towerTableId: any){
    var accesstoken: any;
    accesstoken = sessionStorage.getItem("token")
    var tokenheaders = new HttpHeaders({
      Authorization: "Bearer " + accesstoken
    });
    return this.http.delete<any>(this.baseUrl + data.api.dashoard.towers.towers+"?id="+towerTableId, { headers: tokenheaders });  }
  
  getTowers(verification_status:any='',transmissionlineid:any='',limit:any='',sortBy:any='',sortOrder:any=''){
    var accesstoken: any;
    accesstoken = sessionStorage.getItem("token")
    var tokenheaders = new HttpHeaders({
      Authorization: "Bearer " + accesstoken
    });
    return this.http.get<any>(this.baseUrl + data.api.dashoard.towers.towers+"?verification_status="+verification_status+'&transmissionlineid='+transmissionlineid+"&limit="+limit+'&sortBy='+sortBy+'&sortOrder='+sortOrder, { headers: tokenheaders });
  }
  sendTowerToVerify(putData:any){
    var accesstoken: any;
    accesstoken = sessionStorage.getItem("token")
    var tokenheaders = new HttpHeaders({
      Authorization: "Bearer " + accesstoken
    });
    return this.http.put<any>(this.baseUrl + data.api.dashoard.towers.sendToVerify,putData, { headers: tokenheaders });
  }
  verifyTower(putData:any){
    var accesstoken: any;
    accesstoken = sessionStorage.getItem("token")
    var tokenheaders = new HttpHeaders({
      Authorization: "Bearer " + accesstoken
    });
    return this.http.put<any>(this.baseUrl + data.api.dashoard.towers.verifyTower,putData, { headers: tokenheaders });
  }
  verifyTowerTransmisssion(putData:any){
    var accesstoken: any;
    accesstoken = sessionStorage.getItem("token")
    var tokenheaders = new HttpHeaders({
      Authorization: "Bearer " + accesstoken
    });
    return this.http.put<any>(this.baseUrl + data.api.dashoard.towers.transmissionlineTowerVerification,putData, { headers: tokenheaders });
  }
  multipleVerification(putData:any){
    var accesstoken: any;
    accesstoken = sessionStorage.getItem("token")
    var tokenheaders = new HttpHeaders({
      Authorization: "Bearer " + accesstoken
    });
    return this.http.put<any>(this.baseUrl + data.api.dashoard.towers.transmissionlineMultipleVerification,putData, { headers: tokenheaders });
  }

  fetchTowersById(towerTableId: any){
    var accesstoken: any;
    accesstoken = sessionStorage.getItem("token")
    var tokenheaders = new HttpHeaders({
      Authorization: "Bearer " + accesstoken
    });
    return this.http.get<any>(this.baseUrl + data.api.dashoard.towers.fetchTowersById+"?id="+towerTableId, { headers: tokenheaders });
  }

  fetchTowersByTransmissionId(transmissionId: any,page:any='',limit:any=''){
    var accesstoken: any;
    accesstoken = sessionStorage.getItem("token")
    var tokenheaders = new HttpHeaders({
      Authorization: "Bearer " + accesstoken
    });
    return this.http.get<any>(this.baseUrl + data.api.dashoard.towers.towers+"?transmissionlineid="+transmissionId+"&limit="+limit+"&page="+page, { headers: tokenheaders });
  }

  fetchTowerAttributes(TOWERS:any) {
    var accesstoken: any;
    accesstoken = sessionStorage.getItem("token")
    var tokenheaders = new HttpHeaders({
      Authorization: "Bearer " + accesstoken
    });
    return this.http.get<any>(this.baseUrl + data.api.dashoard.transmissionlineAttributes.transmissionlineAttributes+'?group_type='+TOWERS, { headers: tokenheaders });
  }
  addtowerAttributes(postData:any){
    var accesstoken: any;
    accesstoken = sessionStorage.getItem("token")
    var tokenheaders = new HttpHeaders({
      Authorization: "Bearer " + accesstoken
    });
    return this.http.post<any>(this.baseUrl + data.api.dashoard.collectionAttribute.collectionAttribute+'?group_type=TOWERS',postData, { headers: tokenheaders });
  }

  getCollectionAttribute(TOWERS:any){
    var accesstoken: any;
    accesstoken = sessionStorage.getItem("token")
    var tokenheaders = new HttpHeaders({
      Authorization: "Bearer " + accesstoken
    });
    return this.http.get<any>(this.baseUrl + data.api.dashoard.collectionAttribute.collectionAttribute+"?group_type="+TOWERS, { headers: tokenheaders });

  }

  fetchUsers(page: any, limit: any, id: any) {
    var accesstoken: any;
    accesstoken = sessionStorage.getItem("token")
    var tokenheaders = new HttpHeaders({
      Authorization: "Bearer " + accesstoken
    });
    return this.http.get<any>(this.baseUrl + data.api.dashoard.employee.fetchUser + '?substation_id=' + id, { headers: tokenheaders });
  }
  userrestpassword(postData: any) {
    var accesstoken: any;
    accesstoken = sessionStorage.getItem("token")
    var tokenheaders = new HttpHeaders({
      Authorization: "Bearer " + accesstoken
    });
    return this.http.post(this.baseUrl + data.api.dashoard.employee.resetpassword, postData, { headers: tokenheaders });
  }

  editEmployee(putData: any) {
    var accesstoken: any;
    accesstoken = sessionStorage.getItem("token")
    var tokenheaders = new HttpHeaders({
      Authorization: "Bearer " + accesstoken
    });
    return this.http.put<any>(this.baseUrl + data.api.dashoard.employee.employee, putData, { headers: tokenheaders });
  }
  subStationHead(substationid: any) {
    var accesstoken: any;
    accesstoken = sessionStorage.getItem("token")
    var tokenheaders = new HttpHeaders({
      Authorization: "Bearer " + accesstoken
    });
    return this.http.get<any>(this.baseUrl + data.api.dashoard.employee.substationhead +'?substationid='+ substationid, { headers: tokenheaders })
  }


  fetchEmployeeById(empid: any) {
    var accesstoken: any;
    accesstoken = sessionStorage.getItem("token")
    var tokenheaders = new HttpHeaders({
      Authorization: "Bearer " + accesstoken
    });
    return this.http.get<any>(this.baseUrl + data.api.dashoard.employee.employee + '/' + empid, { headers: tokenheaders })
  }

  deleteEmployee(empid: any) {
    var accesstoken: any;
    accesstoken = sessionStorage.getItem("token")
    var tokenheaders = new HttpHeaders({
      Authorization: "Bearer " + accesstoken
    });
    return this.http.delete<any>(this.baseUrl + data.api.dashoard.employee.employee + '/' + empid, { headers: tokenheaders })
  }


  getRoles() {
    var accesstoken: any;
    accesstoken = sessionStorage.getItem("token")
    var tokenheaders = new HttpHeaders({
      Authorization: "Bearer " + accesstoken
    });
    return this.http.get<any>(this.baseUrl + data.api.dashoard.role.role, { headers: tokenheaders });
  }

  addRoles(postData: any) {
    var accesstoken: any;
    accesstoken = sessionStorage.getItem("token")
    var tokenheaders = new HttpHeaders({
      Authorization: "Bearer " + accesstoken
    });
    return this.http.post(this.baseUrl + data.api.dashoard.role.role, postData, { headers: tokenheaders });
  }
  fetchRoles(id:any) {
    var accesstoken: any;
    accesstoken = sessionStorage.getItem("token")
    var tokenheaders = new HttpHeaders({
      Authorization: "Bearer " + accesstoken
    });
    return this.http.get(this.baseUrl + data.api.dashoard.role.fetchRole+'?role_id='+ id, { headers: tokenheaders });
  }
  editRoles(putData: any) {
    var accesstoken: any;
    accesstoken = sessionStorage.getItem("token")
    var tokenheaders = new HttpHeaders({
      Authorization: "Bearer " + accesstoken
    });
    return this.http.put(this.baseUrl + data.api.dashoard.role.role, putData, { headers: tokenheaders });
  }

  fileupload(postData: any) {
    var accesstoken: any;
    accesstoken = sessionStorage.getItem("token")
    var tokenheaders = new HttpHeaders({
      Authorization: "Bearer " + accesstoken
    });
    return this.http.post(this.baseUrl + data.api.dashoard.fileUpload.fileUpload, postData, { headers: tokenheaders });
  }



  getFeatures() {
    var accesstoken: any;
    accesstoken = sessionStorage.getItem("token")
    var tokenheaders = new HttpHeaders({
      Authorization: "Bearer " + accesstoken
    });
    return this.http.get<any>(this.baseUrl + data.api.dashoard.features.features, { headers: tokenheaders });
  }

  fetchFeaturesById(roleid: any) {
    var accesstoken: any;
    accesstoken = sessionStorage.getItem("token")
    var tokenheaders = new HttpHeaders({
      Authorization: "Bearer " + accesstoken
    });
    return this.http.get<any>(this.baseUrl + data.api.dashoard.features.features + '/' + roleid, { headers: tokenheaders })
  }

  addFeatureRole(postData: any) {
    var accesstoken: any;
    accesstoken = sessionStorage.getItem("token")
    var tokenheaders = new HttpHeaders({
      Authorization: "Bearer " + accesstoken
    });
    return this.http.post(this.baseUrl + data.api.dashoard.featurerole.featurerole, postData, { headers: tokenheaders });
  }

  getFeatureRole(id: any) {
    var accesstoken: any;
    accesstoken = sessionStorage.getItem("token");
    var tokenheaders = new HttpHeaders({
      Authorization: "Bearer " + accesstoken
    });
    return this.http.get<any>(this.baseUrl + data.api.dashoard.featurerole.featurerole + "/" + id, { headers: tokenheaders });
  }
  // ...................................................

  addZone(postData: any) {
    var accesstoken: any;
    accesstoken = sessionStorage.getItem("token")
    var tokenheaders = new HttpHeaders({
      Authorization: "Bearer " + accesstoken
    });
    return this.http.post(this.baseUrl + data.api.dashoard.zone.addZone, postData, { headers: tokenheaders });
  }

  getZone(page: any, limit: any,sortBy:any='',sortOrder:any='') {
    var accesstoken: any;
    accesstoken = sessionStorage.getItem("token")
    var tokenheaders = new HttpHeaders({
      Authorization: "Bearer " + accesstoken
    });
    return this.http.get<any>(this.baseUrl + data.api.dashoard.zone.addZone + '?page=' + page + '&limit=' + limit+'&sortBy='+sortBy+'&sortOrder='+sortOrder, { headers: tokenheaders });
  }

  ZonePublicAPI(page: any, limit: any) {
    var accesstoken: any;
    accesstoken = sessionStorage.getItem("token")
    var tokenheaders = new HttpHeaders({
      Authorization: "Bearer " + accesstoken
    });
    return this.http.get<any>(this.baseUrl + data.api.dashoard.publicAPI.zonename + '?page=' + page + '&limit=' + limit, { headers: tokenheaders });
  }

  editzone(putData: any) {
    var accesstoken: any;
    accesstoken = sessionStorage.getItem("token")
    var tokenheaders = new HttpHeaders({
      Authorization: "Bearer " + accesstoken
    });
    return this.http.put<any>(this.baseUrl + data.api.dashoard.zone.addZone, putData, { headers: tokenheaders });
  }


  fetchZoneById(zoneid: any) {
    var accesstoken: any;
    accesstoken = sessionStorage.getItem("token")
    var tokenheaders = new HttpHeaders({
      Authorization: "Bearer " + accesstoken
    });
    return this.http.get<any>(this.baseUrl + data.api.dashoard.zone.addZone + '/' + zoneid, { headers: tokenheaders })
  }

  deleteZone(zoneid: any) {
    var accesstoken: any;
    accesstoken = sessionStorage.getItem("token")
    var tokenheaders = new HttpHeaders({
      Authorization: "Bearer " + accesstoken
    });
    return this.http.delete<any>(this.baseUrl + data.api.dashoard.zone.addZone + '/' + zoneid, { headers: tokenheaders })
  }

  // ...............................................


  getCircle(id = '',sortBy:any='',sortOrder:any='') {
    var accesstoken: any;
    accesstoken = sessionStorage.getItem("token")
    var tokenheaders = new HttpHeaders({
      Authorization: "Bearer " + accesstoken
    });
    return this.http.get<any>(this.baseUrl + data.api.dashoard.circle.addCircle + '?zoneid=' + id+'&sortBy='+sortBy+'&sortOrder='+sortOrder, { headers: tokenheaders });
  }

  publicCircleAPI(id = '') {
    var accesstoken: any;
    accesstoken = sessionStorage.getItem("token")
    var tokenheaders = new HttpHeaders({
      Authorization: "Bearer " + accesstoken
    });
    return this.http.get<any>(this.baseUrl + data.api.dashoard.publicAPI.circlename + '?zoneid=' + id, { headers: tokenheaders });
  }

  addCircle(postData: any) {
    var accesstoken: any;
    accesstoken = sessionStorage.getItem("token")
    var tokenheaders = new HttpHeaders({
      Authorization: "Bearer " + accesstoken
    });
    return this.http.post(this.baseUrl + data.api.dashoard.circle.addCircle, postData, { headers: tokenheaders });
  }
  fetchCircleById(circleid: any) {
    var accesstoken: any;
    accesstoken = sessionStorage.getItem("token")
    var tokenheaders = new HttpHeaders({
      Authorization: "Bearer " + accesstoken
    });
    return this.http.get<any>(this.baseUrl + data.api.dashoard.circle.addCircle + '/' + circleid, { headers: tokenheaders })
  }
  editcircle(putData: any) {
    var accesstoken: any;
    accesstoken = sessionStorage.getItem("token")
    var tokenheaders = new HttpHeaders({
      Authorization: "Bearer " + accesstoken
    });
    return this.http.put<any>(this.baseUrl + data.api.dashoard.circle.addCircle, putData, { headers: tokenheaders });
  }

  deleteCircle(circleid: any) {
    var accesstoken: any;
    accesstoken = sessionStorage.getItem("token")
    var tokenheaders = new HttpHeaders({
      Authorization: "Bearer " + accesstoken
    });
    return this.http.delete<any>(this.baseUrl + data.api.dashoard.circle.addCircle + '/' + circleid, { headers: tokenheaders })
  }
  // ..........................................


  getDivision(id = '',sortBy:any='',sortOrder:any='') {
    var accesstoken: any;
    accesstoken = sessionStorage.getItem("token")
    var tokenheaders = new HttpHeaders({  
      Authorization: "Bearer " + accesstoken
    });
    return this.http.get<any>(this.baseUrl + data.api.dashoard.division.addDivision + '?circleid=' + id+'&sortBy='+sortBy+'&sortOrder='+sortOrder, { headers: tokenheaders });
  }

  publiDivisionAPI(id = '') {
    var accesstoken: any;
    accesstoken = sessionStorage.getItem("token")
    var tokenheaders = new HttpHeaders({  
      Authorization: "Bearer " + accesstoken
    });
    return this.http.get<any>(this.baseUrl + data.api.dashoard.publicAPI.divisionname + '?circleid=' + id, { headers: tokenheaders });
  }

  addDivision(postData: any) {
    var accesstoken: any;
    accesstoken = sessionStorage.getItem("token")
    var tokenheaders = new HttpHeaders({
      Authorization: "Bearer " + accesstoken
    });
    return this.http.post(this.baseUrl + data.api.dashoard.division.addDivision, postData, { headers: tokenheaders });
  }


  fetchDivisionById(divisionid: any) {
    var accesstoken: any;
    accesstoken = sessionStorage.getItem("token")
    var tokenheaders = new HttpHeaders({
      Authorization: "Bearer " + accesstoken
    });
    return this.http.get<any>(this.baseUrl + data.api.dashoard.division.addDivision + '/' + divisionid, { headers: tokenheaders })
  }
  editdivision(putData: any) {
    var accesstoken: any;
    accesstoken = sessionStorage.getItem("token")
    var tokenheaders = new HttpHeaders({
      Authorization: "Bearer " + accesstoken
    });
    return this.http.put<any>(this.baseUrl + data.api.dashoard.division.addDivision, putData, { headers: tokenheaders });
  }

  deleteDivision(divisionid: any) {
    var accesstoken: any;
    accesstoken = sessionStorage.getItem("token")
    var tokenheaders = new HttpHeaders({
      Authorization: "Bearer " + accesstoken
    });
    return this.http.delete<any>(this.baseUrl + data.api.dashoard.division.addDivision + '/' + divisionid, { headers: tokenheaders })
  }

  // ..................................

  getSubDivision(page: any, limit: any) {
    var accesstoken: any;
    accesstoken = sessionStorage.getItem("token")
    var tokenheaders = new HttpHeaders({
      Authorization: "Bearer " + accesstoken
    });
    return this.http.get<any>(this.baseUrl + data.api.dashoard.subdivision.addSubDivision, { headers: tokenheaders });
  }

  addSubDivision(postData: any) {
    var accesstoken: any;
    accesstoken = sessionStorage.getItem("token")
    var tokenheaders = new HttpHeaders({
      Authorization: "Bearer " + accesstoken
    });
    return this.http.post(this.baseUrl + data.api.dashoard.subdivision.addSubDivision, postData, { headers: tokenheaders });
  }


  fetchSubDivisionById(subdiv: any) {
    var accesstoken = sessionStorage.getItem("token")
    var tokenheaders = new HttpHeaders({
      Authorization: `{accesstoken}`
    });
    return this.http.get<any>(this.baseUrl + data.api.dashoard.subdivision.addSubDivision + '/' + subdiv, { headers: tokenheaders })
  }
  editSubdivision(putData: any) {
    var accesstoken: any;
    accesstoken = sessionStorage.getItem("token")
    var tokenheaders = new HttpHeaders({
      Authorization: "Bearer " + accesstoken
    });
    return this.http.put<any>(this.baseUrl + data.api.dashoard.subdivision.addSubDivision, putData, { headers: tokenheaders });
  }

  deleteSubDivision(subdivisionid: any) {
    var accesstoken: any;
    accesstoken = sessionStorage.getItem("token")
    var tokenheaders = new HttpHeaders({
      Authorization: "Bearer " + accesstoken
    });
    return this.http.delete<any>(this.baseUrl + data.api.dashoard.subdivision.addSubDivision + '/' + subdivisionid, { headers: tokenheaders })
  }


  filterDivisions(zoneId: any, circleid: any,sortBy:any='',sortOrder:any='') {
    var accesstoken: any;
    accesstoken = sessionStorage.getItem("token")
    var tokenheaders = new HttpHeaders({
      Authorization: "Bearer " + accesstoken
    });
    return this.http.get<any>(this.baseUrl + data.api.dashoard.division.addDivision + '?zoneid=' + zoneId + "&circleid=" + circleid +'&sortBy='+sortBy+'&sortOrder='+sortOrder, { headers: tokenheaders });
  }

  // ................................................

  getSubStation(key: any, value: any,limit:any='',sortBy:any='',sortOrder:any='') {
    var accesstoken: any;
    accesstoken = sessionStorage.getItem("token")
    var tokenheaders = new HttpHeaders({
      Authorization: "Bearer " + accesstoken
    });
    return this.http.get<any>(this.baseUrl + data.api.dashoard.substation.addSubStation+"?limit="+limit+'&sortBy='+sortBy+'&sortOrder='+sortOrder , { headers: tokenheaders });
  }

  fetchSubStationAttributes(substation_attributes:any) {
    var accesstoken: any;
    accesstoken = sessionStorage.getItem("token")
    var tokenheaders = new HttpHeaders({
      Authorization: "Bearer " + accesstoken
    });
    return this.http.get<any>(this.baseUrl + data.api.dashoard.transmissionlineAttributes.transmissionlineAttributes+'?group_type='+substation_attributes, { headers: tokenheaders });
  }

  addSubStationAttributes(postData:any){
    var accesstoken: any;
    accesstoken = sessionStorage.getItem("token")
    var tokenheaders = new HttpHeaders({
      Authorization: "Bearer " + accesstoken
    });
    return this.http.post<any>(this.baseUrl + data.api.dashoard.collectionAttribute.collectionAttribute+'?group_type=SUBSTATION',postData, { headers: tokenheaders });
  }

  collectionAttributeSubStation(SUBSTATION: any){
    var accesstoken: any;
    accesstoken = sessionStorage.getItem("token")
    var tokenheaders = new HttpHeaders({
      Authorization: "Bearer " + accesstoken
    });
    return this.http.get<any>(this.baseUrl+data.api.dashoard.collectionAttribute.collectionAttribute+"?group_type="+SUBSTATION,{headers:tokenheaders})
  }

  getSubStationbyDivision( GptTOOi1ts5t: any) {
    var accesstoken: any;
    accesstoken = sessionStorage.getItem("token")
    var tokenheaders = new HttpHeaders({
      Authorization: "Bearer " + accesstoken
    });
    return this.http.get<any>(this.baseUrl + data.api.dashoard.substation.substationName+'?divisionid='+ GptTOOi1ts5t , { headers: tokenheaders });
  }

  fetchAllSubstations(key: any, value: any,viewby:any='') {
    var accesstoken: any;
    accesstoken = sessionStorage.getItem("token")
    var tokenheaders = new HttpHeaders({
      Authorization: "Bearer " + accesstoken
    });
    return this.http.get<any>(this.baseUrl + data.api.dashoard.substation.fetchAllsubstations+'?'+viewby, { headers: tokenheaders });
  }

  fetchSubstationByID(subStationID:string) {
    var accesstoken: any;
    accesstoken = sessionStorage.getItem("token")
    var tokenheaders = new HttpHeaders({
      Authorization: "Bearer " + accesstoken
    });
    return this.http.get<any>(this.baseUrl + data.api.dashoard.substation.fetchAllsubstations+"/"+subStationID, { headers: tokenheaders });
  }

  getSubStationfiltering(voltage: any, district: any, consistuency: any, zoneId: any, circleid: any, divisionid: any, page: any,limit:any='',sortBy:any='',sortOrder:any='') {
    var accesstoken: any;
    accesstoken = sessionStorage.getItem("token")
    var tokenheaders = new HttpHeaders({
      Authorization: "Bearer " + accesstoken
    });
    return this.http.get<any>(this.baseUrl + data.api.dashoard.substation.addSubStation + '?voltage=' + voltage + "&district=" + district + "&constituency=" + consistuency + "&zoneid=" + zoneId + "&circleid=" + circleid + "&divisionid=" + divisionid + "&page=" + page+"&limit="+limit+'&sortBy='+sortBy+'&sortOrder='+sortOrder, { headers: tokenheaders });
  }

  SubStationName(page: any, limit: any) {
    var accesstoken: any;
    accesstoken = sessionStorage.getItem("token")
    var tokenheaders = new HttpHeaders({
      Authorization: "Bearer " + accesstoken
    });
    return this.http.get<any>(this.baseUrl + data.api.dashoard.substation.substationName, { headers: tokenheaders });
  }

  addSubStation(postData: any) {
    var accesstoken: any;
    accesstoken = sessionStorage.getItem("token")
    var tokenheaders = new HttpHeaders({
      Authorization: "Bearer " + accesstoken
    });
    return this.http.post(this.baseUrl + data.api.dashoard.substation.addSubStation, postData, { headers: tokenheaders });
  }


  getSubStationCount(page: any, limit: any, id: any) {
    var accesstoken: any;
    accesstoken = sessionStorage.getItem("token")
    var tokenheaders = new HttpHeaders({
      Authorization: "Bearer " + accesstoken
    });
    return this.http.get<any>(this.baseUrl + data.api.dashoard.substation.substationReportCount + '?sub_station_id=' + id, { headers: tokenheaders });
  }

  viewSubStationEquipment(id: any) {
    var accesstoken: any;
    accesstoken = sessionStorage.getItem("token")
    var tokenheaders = new HttpHeaders({
      Authorization: "Bearer " + accesstoken
    });
    return this.http.get<any>(this.baseUrl + data.api.dashoard.substationEquipment.substationEquipment + '?sub_station_id=' + id, { headers: tokenheaders });
  }

  pdfDetails(id: any) {
    var accesstoken: any;
    accesstoken = sessionStorage.getItem("token")
    var tokenheaders = new HttpHeaders({
      Authorization: "Bearer " + accesstoken
    });
    return this.http.get<any>(this.baseUrl + data.api.dashoard.report.pdfDetails + '?sub_station_id=' + id, { headers: tokenheaders });
  }

  countReport() {
    var accesstoken: any;
    accesstoken = sessionStorage.getItem("token")
    var tokenheaders = new HttpHeaders({
      Authorization: "Bearer " + accesstoken
    });
    return this.http.get<any>(this.baseUrl + data.api.dashoard.report.countReport, { headers: tokenheaders });
  }

  viewSubStationEquipmentData(id: any, sub_station_equ_id: any) {
    var accesstoken: any;
    accesstoken = sessionStorage.getItem("token")
    var tokenheaders = new HttpHeaders({
      Authorization: "Bearer " + accesstoken
    });
    return this.http.get<any>(this.baseUrl + data.api.dashoard.substationEquipment.substationEquipmentdata + '?sub_station_equipment_id=' + sub_station_equ_id + '&equipment_type_id=' + id, { headers: tokenheaders });
  }

  deleteSubstationEquipment(id: any) {
    var accesstoken: any;
    accesstoken = sessionStorage.getItem("token")
    var tokenheaders = new HttpHeaders({
      Authorization: "Bearer " + accesstoken
    });
    return this.http.delete<any>(this.baseUrl + data.api.dashoard.substationEquipment.substationEquipment + '?id=' + id, { headers: tokenheaders })
  }

  substationavailability(putData: any) {
    var accesstoken: any;
    accesstoken = sessionStorage.getItem("token")
    var tokenheaders = new HttpHeaders({
      Authorization: "Bearer " + accesstoken
    });
    return this.http.put<any>(this.baseUrl + data.api.dashoard.subStationAvaliability.subStationAvaliability, putData, { headers: tokenheaders });
  }

  addNewSubStationEquipment(postData: any) {
    var accesstoken: any;
    accesstoken = sessionStorage.getItem("token")
    var tokenheaders = new HttpHeaders({
      Authorization: "Bearer " + accesstoken
    });
    return this.http.post(this.baseUrl + data.api.dashoard.substationEquipment.substationEquipment, postData, { headers: tokenheaders });
  }

  fetchSubStationById(substation: any) {
    var accesstoken: any;
    accesstoken = sessionStorage.getItem("token")
    var tokenheaders = new HttpHeaders({
      Authorization: "Bearer " + accesstoken
    });
    return this.http.get<any>(this.baseUrl + data.api.dashoard.substation.addSubStation + '/' + substation, { headers: tokenheaders })
  }
  editSubStation(putData: any) {
    var accesstoken: any;
    accesstoken = sessionStorage.getItem("token")
    var tokenheaders = new HttpHeaders({
      Authorization: "Bearer " + accesstoken
    });
    return this.http.put<any>(this.baseUrl + data.api.dashoard.substation.addSubStation, putData, { headers: tokenheaders });
  }

  deleteSubStation(substationid: any) {
    var accesstoken: any;
    accesstoken = sessionStorage.getItem("token")
    var tokenheaders = new HttpHeaders({
      Authorization: "Bearer " + accesstoken
    });
    return this.http.delete<any>(this.baseUrl + data.api.dashoard.substation.addSubStation + '/' + substationid, { headers: tokenheaders })
  }

  toVerfy(putData: any) {
    var accesstoken: any;
    accesstoken = sessionStorage.getItem("token")
    var tokenheaders = new HttpHeaders({
      Authorization: "Bearer " + accesstoken
    });
    return this.http.put<any>(this.baseUrl + data.api.dashoard.toVerfy.toVerfy, putData, { headers: tokenheaders });
  }

  verifyBayType(putData: any) {
    var accesstoken: any;
    accesstoken = sessionStorage.getItem("token")
    var tokenheaders = new HttpHeaders({
      Authorization: "Bearer " + accesstoken
    });
    return this.http.put<any>(this.baseUrl + data.api.dashoard.toVerfy.verifyBayType, putData, { headers: tokenheaders });
  }

  updateVerify(putData: any) {
    var accesstoken: any;
    accesstoken = sessionStorage.getItem("token")
    var tokenheaders = new HttpHeaders({
      Authorization: "Bearer " + accesstoken
    });
    return this.http.put<any>(this.baseUrl + data.api.dashoard.toVerfy.updateVerify, putData, { headers: tokenheaders });
  }

  equipmentDataVerify(putData: any) {
    var accesstoken: any;
    accesstoken = sessionStorage.getItem("token")
    var tokenheaders = new HttpHeaders({
      Authorization: "Bearer " + accesstoken
    });
    return this.http.put<any>(this.baseUrl + data.api.dashoard.toVerfy.equipmentDataVerify, putData, { headers: tokenheaders });
  }

  getVerfierData(page: any, limit: any,viewby:any='',sortBy:any='',sortOrder:any='') {
    var accesstoken: any;
    accesstoken = sessionStorage.getItem("token")
    var tokenheaders = new HttpHeaders({
      Authorization: "Bearer " + accesstoken
    });
    return this.http.get<any>(this.baseUrl + data.api.dashoard.toVerfy.getVerify+'?'+viewby+'&sortBy='+sortBy+'&sortOrder='+sortOrder,{ headers: tokenheaders });
  }

  getEnums(page: any, limit: any) {
    var accesstoken: any;
    accesstoken = sessionStorage.getItem("token")
    var tokenheaders = new HttpHeaders({
      Authorization: "Bearer " + accesstoken
    });
    return this.http.get<any>(this.baseUrl + data.api.dashoard.enums.getEnums, { headers: tokenheaders });
  }

  // ....................................................

  getBay(page: any, limit: any) {
    var accesstoken: any;
    accesstoken = sessionStorage.getItem("token")
    var tokenheaders = new HttpHeaders({
      Authorization: "Bearer " + accesstoken
    });
    return this.http.get<any>(this.baseUrl + data.api.dashoard.bay.addBay, { headers: tokenheaders });
  }

  addBay(postData: any) {
    var accesstoken: any;
    accesstoken = sessionStorage.getItem("token")
    var tokenheaders = new HttpHeaders({
      Authorization: "Bearer " + accesstoken
    });
    return this.http.post(this.baseUrl + data.api.dashoard.bay.addBay, postData, { headers: tokenheaders });
  }
  bayequipment(id: any) {
    var accesstoken: any;
    accesstoken = sessionStorage.getItem("token")
    var tokenheaders = new HttpHeaders({
      Authorization: "Bearer " + accesstoken
    });
    return this.http.get<any>(this.baseUrl + data.api.dashoard.getbay.getbay + '?bay_id=' + id, { headers: tokenheaders })
  }


  fetchBayById(bay: any) {
    var accesstoken: any;
    accesstoken = sessionStorage.getItem("token")
    var tokenheaders = new HttpHeaders({
      Authorization: "Bearer " + accesstoken
    });
    return this.http.get<any>(this.baseUrl + data.api.dashoard.bay.addBay + '/' + bay, { headers: tokenheaders })
  }
  editBay(putData: any) {
    var accesstoken: any;
    accesstoken = sessionStorage.getItem("token")
    var tokenheaders = new HttpHeaders({
      Authorization: "Bearer " + accesstoken
    });
    return this.http.put<any>(this.baseUrl + data.api.dashoard.bay.addBay, putData, { headers: tokenheaders });
  }
  deleteBayEquipment(id: any) {
    var accesstoken: any;
    accesstoken = sessionStorage.getItem("token")
    var tokenheaders = new HttpHeaders({
      Authorization: "Bearer " + accesstoken
    });
    return this.http.delete<any>(this.baseUrl + data.api.dashoard.getbay.getbay + '?id=' + id, { headers: tokenheaders })
  }


  addNewBayEquipment(postData: any) {
    var accesstoken: any;
    accesstoken = sessionStorage.getItem("token")
    var tokenheaders = new HttpHeaders({
      Authorization: "Bearer " + accesstoken
    });
    return this.http.post(this.baseUrl + data.api.dashoard.addNewBayEquipment.addNewBayEquipment, postData, { headers: tokenheaders });
  }

  deleteBay(bayid: any) {
    var accesstoken: any;
    accesstoken = sessionStorage.getItem("token")
    var tokenheaders = new HttpHeaders({
      Authorization: "Bearer " + accesstoken
    });
    return this.http.delete<any>(this.baseUrl + data.api.dashoard.bay.addBay + '/' + bayid, { headers: tokenheaders })
  }
  // ............................................. bay types
  getBayType(sortBy:any='',sortOrder:any='') {
    var accesstoken: any;
    accesstoken = sessionStorage.getItem("token")
    var tokenheaders = new HttpHeaders({
      Authorization: "Bearer " + accesstoken
    });
    return this.http.get<any>(this.baseUrl + data.api.dashoard.baytype.baytype +'?sortBy='+sortBy+'&sortOrder='+sortOrder, { headers: tokenheaders });
  }

  UpdateBayType(putData: any) {
    var accesstoken: any;
    accesstoken = sessionStorage.getItem("token")
    var tokenheaders = new HttpHeaders({
      Authorization: "Bearer " + accesstoken
    });
    return this.http.put<any>(this.baseUrl + data.api.dashoard.baytype.baytype, putData, { headers: tokenheaders });

  }

  addBayType(postData: any) {
    var accesstoken: any;
    accesstoken = sessionStorage.getItem("token")
    var tokenheaders = new HttpHeaders({
      Authorization: "Bearer " + accesstoken
    });
    return this.http.post(this.baseUrl + data.api.dashoard.baytype.baytype, postData, { headers: tokenheaders });
  }


  fetchBayTypeById(id: any) {
    var accesstoken: any;
    accesstoken = sessionStorage.getItem("token")
    var tokenheaders = new HttpHeaders({
      Authorization: "Bearer " + accesstoken
    });
    return this.http.get<any>(this.baseUrl + data.api.dashoard.baytype.baytypeById + '?bay_type_id=' + id, { headers: tokenheaders })
  }

  bayMapping(postData: any) {
    var accesstoken: any;
    accesstoken = sessionStorage.getItem("token")
    var tokenheaders = new HttpHeaders({
      Authorization: "Bearer " + accesstoken
    });
    return this.http.post<any>(this.baseUrl + data.api.dashoard.bayMapping.bayMapping, postData, { headers: tokenheaders });
  }

  fetchSubstationBayMapping(substationid: any,verification_status :any='',sortBy:any='',sortOrder:any='',page:any='',limit:any='') {
    var accesstoken: any;
    accesstoken = sessionStorage.getItem("token")
    var tokenheaders = new HttpHeaders({
      Authorization: "Bearer " + accesstoken
    });
    return this.http.get<any>(this.baseUrl + data.api.dashoard.bayMapping.bayMapping + "?sub_station_id=" + substationid +"&verification_status="+verification_status+'&sortBy='+sortBy+'&sortOrder='+sortOrder+'&page='+page+"&limit="+limit, { headers: tokenheaders })
  }

  UpdateBay(putData: any) {
    var accesstoken: any;
    accesstoken = sessionStorage.getItem("token")
    var tokenheaders = new HttpHeaders({
      Authorization: "Bearer " + accesstoken
    });
    return this.http.put<any>(this.baseUrl + data.api.dashoard.bayMapping.bayMapping, putData, { headers: tokenheaders });

  }
  EditSubStationBayMapping(putData:any){
    var accesstoken: any;
    accesstoken = sessionStorage.getItem("token")
    var tokenheaders = new HttpHeaders({
      Authorization: "Bearer " + accesstoken
    });
    return this.http.put<any>(this.baseUrl + data.api.dashoard.bayMapping.Editsubstationbaymapping, putData, { headers: tokenheaders });

  }

  fetchSubstationBayMappingById(substationbayid: any, equipment_availability: any) {
    var accesstoken: any;
    accesstoken = sessionStorage.getItem("token")
    var tokenheaders = new HttpHeaders({
      Authorization: "Bearer " + accesstoken
    });
    return this.http.get<any>(this.baseUrl + data.api.dashoard.bayMapping.bayMappingEquipments + "?sub_station_bay_id=" + substationbayid + "&equipment_availability=" + equipment_availability, { headers: tokenheaders })
  }

  equipmentAvailabilitySubStationBayMapping(putData: any) {
    var accesstoken: any;
    accesstoken = sessionStorage.getItem("token")
    var tokenheaders = new HttpHeaders({
      Authorization: "Bearer " + accesstoken
    });
    return this.http.put<any>(this.baseUrl + data.api.dashoard.bayMapping.equipmentavailability, putData, { headers: tokenheaders });
  }
  fetchBaySubStationBayMappingEquipmentData(ss_bay_equipment_id: any, equipment_type_id: any) {
    var accesstoken: any;
    accesstoken = sessionStorage.getItem("token");
    var tokenheaders = new HttpHeaders({
      Authorization: "Bearer " + accesstoken
    });
    return this.http.get(this.baseUrl + data.api.dashoard.bayMapping.equipmentavailabilityfields + '?ss_bay_equipment_id=' + ss_bay_equipment_id + "&equipment_type_id=" + equipment_type_id, { headers: tokenheaders });
  }

  fetchBayAttributes(bay_type_id: any, sub_station_bay_id: any) {
    var accesstoken: any;
    accesstoken = sessionStorage.getItem("token");
    var tokenheaders = new HttpHeaders({
      Authorization: "Bearer " + accesstoken
    });
    return this.http.get(this.baseUrl + data.api.dashoard.bayMapping.fetchBayAttributes + '?bay_type_id=' + bay_type_id + "&sub_station_bay_id=" + sub_station_bay_id, { headers: tokenheaders });
  }

  sendSubStationBayMappingEquipmentData(putData: any) {
    var accesstoken: any;
    accesstoken = sessionStorage.getItem("token");
    var tokenheaders = new HttpHeaders({
      Authorization: "Bearer " + accesstoken
    });
    return this.http.put(this.baseUrl + data.api.dashoard.bayMapping.equipmentavailabilityfields, putData, { headers: tokenheaders });
  }
  editBayType(putData: any) {
    var accesstoken: any;
    accesstoken = sessionStorage.getItem("token")
    var tokenheaders = new HttpHeaders({
      Authorization: "Bearer " + accesstoken
    });
    return this.http.put<any>(this.baseUrl + data.api.dashoard.baytype.baytype, putData, { headers: tokenheaders });
  }

  deleteBayTypeField(id: any, baytypeid: any) {
    var accesstoken: any;
    accesstoken = sessionStorage.getItem("token")
    var tokenheaders = new HttpHeaders({
      Authorization: "Bearer " + accesstoken
    });
    return this.http.delete<any>(this.baseUrl + data.api.dashoard.baytype.baytypeDelete + '?bay_type_id=' + id + '&field_id=' + baytypeid, { headers: tokenheaders })
  }

  deleteBayType(id: any, baytypeid: any) {
    var accesstoken: any;
    accesstoken = sessionStorage.getItem("token")
    var tokenheaders = new HttpHeaders({
      Authorization: "Bearer " + accesstoken
    });
    return this.http.delete<any>(this.baseUrl + data.api.dashoard.baytype.baytypeDelete + '?id=' + id + '&baytypeid=' + baytypeid, { headers: tokenheaders })
  }

  deleteBayType1(id: any) {
    var accesstoken: any;
    accesstoken = sessionStorage.getItem("token")
    var tokenheaders = new HttpHeaders({
      Authorization: "Bearer " + accesstoken
    });
    return this.http.delete<any>(this.baseUrl + data.api.dashoard.baytype.baytype + '?id=' + id, { headers: tokenheaders })
  }

  // ............................................
  // ....................................................transmissionline


  getTransmissionlines(id = '') {
    var accesstoken: any;
    accesstoken = sessionStorage.getItem("token")
    var tokenheaders = new HttpHeaders({
      Authorization: "Bearer " + accesstoken
    });
    return this.http.get<any>(this.baseUrl + data.api.dashoard.transmissionline.transmissionline, { headers: tokenheaders });
  }

  getTransmissionline(page: any, limit: any,byUser:any='',sortBy:any='',sortOrder:any='') {
    var accesstoken: any;
    accesstoken = sessionStorage.getItem("token")
    var tokenheaders = new HttpHeaders({
      Authorization: "Bearer " + accesstoken
    });
    return this.http.get<any>(this.baseUrl + data.api.dashoard.transmissionline.transmissionline+"?byuser="+byUser+'&sortBy='+sortBy+'&sortOrder='+sortOrder+"&limit="+limit, { headers: tokenheaders });
  }

  getTransmissionlineVerification(verification_status:any,sortBy:any='',sortOrder:any='') {
    var accesstoken: any;
    accesstoken = sessionStorage.getItem("token")
    var tokenheaders = new HttpHeaders({
      Authorization: "Bearer " + accesstoken
    });
    return this.http.get<any>(this.baseUrl + data.api.dashoard.transmissionline.getTransmissionVerification+"?verification_status="+verification_status+'&sortBy='+sortBy+'&sortOrder='+sortOrder, { headers: tokenheaders });
  }
  verifyTransmission(putData:any) {
    var accesstoken: any;
    accesstoken = sessionStorage.getItem("token")
    var tokenheaders = new HttpHeaders({
      Authorization: "Bearer " + accesstoken
    });
    return this.http.put<any>(this.baseUrl + data.api.dashoard.transmissionline.transmissionVerify,putData, { headers: tokenheaders });
  }

  verifyTransmissionLine(putData:any) {
    var accesstoken: any;
    accesstoken = sessionStorage.getItem("token")
    var tokenheaders = new HttpHeaders({
      Authorization: "Bearer " + accesstoken
    });
    return this.http.put<any>(this.baseUrl + data.api.dashoard.transmissionline.sendToverifyTransmission, putData, { headers: tokenheaders });
  }
  collectionAttributeTransmissionline(TRANSMISSIONLINE: any){
    var accesstoken: any;
    accesstoken = sessionStorage.getItem("token")
    var tokenheaders = new HttpHeaders({
      Authorization: "Bearer " + accesstoken
    });
    return this.http.get<any>(this.baseUrl+data.api.dashoard.collectionAttribute.collectionAttribute+"?group_type="+TRANSMISSIONLINE,{headers:tokenheaders})
  }

  getTransmissionlineFiltering(voltage: any, district: any,consistuency:any,verificationstatus:any,zoneId:any,circleId:any ,divisionid:any,page:any,byuser:any='',sortBy:any='',sortOrder:any='',limit:any='') {
    var accesstoken: any;

    accesstoken = sessionStorage.getItem("token")
    var tokenheaders = new HttpHeaders({
      Authorization: "Bearer " + accesstoken
    });
    console.log("getTransmissionlineFiltering")
    return this.http.get<any>(this.baseUrl + data.api.dashoard.transmissionline.transmissionline + '?voltage_level=' + voltage +'&district='+district +'&constituency='+consistuency +'&verification_status='+verificationstatus+'&zoneid='+zoneId +'&circleid='+circleId+'&divisionid='+divisionid+"&page="+page+'&byuser='+byuser+'&sortBy='+sortBy+'&sortOrder='+sortOrder+"&limit="+limit, { headers: tokenheaders });
  }

  getTowersFiltering(transimissionlineid: any, verification_status: any,page:any,limit:any,sortBy:any='',sortOrder:any='') {
    var accesstoken: any;

    accesstoken = sessionStorage.getItem("token")
    var tokenheaders = new HttpHeaders({
      Authorization: "Bearer " + accesstoken
    });
    console.log("getTransmissionlineFiltering")
    return this.http.get<any>(this.baseUrl + data.api.dashoard.towers.towers + '?transmissionlineid=' + transimissionlineid +'&verification_status='+verification_status +"&page="+page +"&limit="+limit+'&sortBy='+sortBy+'&sortOrder='+sortOrder, { headers: tokenheaders });
  }

  dropdownTransmissionline(page: any, limit: any,viewby:any='') {
    var accesstoken: any;
    accesstoken = sessionStorage.getItem("token")
    var tokenheaders = new HttpHeaders({
      Authorization: "Bearer " + accesstoken
    });
    return this.http.get<any>(this.baseUrl + data.api.dashoard.transmissionline.DPtransmissionline+'?'+viewby, { headers: tokenheaders });
  }

  substationTransmissionline(page: any, limit: any, id: any) {
    var accesstoken: any;
    accesstoken = sessionStorage.getItem("token")
    var tokenheaders = new HttpHeaders({
      Authorization: "Bearer " + accesstoken
    });
    return this.http.get<any>(this.baseUrl + data.api.dashoard.transmissionline.substationTransmissionline + '?id1=' + id, { headers: tokenheaders });
  }

  addTransmissionline(postData: any) {
    var accesstoken: any;
    accesstoken = sessionStorage.getItem("token")
    var tokenheaders = new HttpHeaders({
      Authorization: "Bearer " + accesstoken
    });
    return this.http.post(this.baseUrl + data.api.dashoard.transmissionline.transmissionline, postData, { headers: tokenheaders });
  }


  fetchTransmissionlineById(substation: any) {
    var accesstoken: any;
    accesstoken = sessionStorage.getItem("token")
    var tokenheaders = new HttpHeaders({
      Authorization: "Bearer " + accesstoken
    });
    return this.http.get<any>(this.baseUrl + data.api.dashoard.transmissionline.transmissionline + '/' + substation, { headers: tokenheaders })
  }

  editTransmissionline(putData: any) {
    var accesstoken: any;
    accesstoken = sessionStorage.getItem("token")
    var tokenheaders = new HttpHeaders({
      Authorization: "Bearer " + accesstoken
    });
    return this.http.put<any>(this.baseUrl + data.api.dashoard.transmissionline.transmissionline, putData, { headers: tokenheaders });
  }

  deleteTransmissionline(transmisionid: any) {
    var accesstoken: any;
    accesstoken = sessionStorage.getItem("token")
    var tokenheaders = new HttpHeaders({
      Authorization: "Bearer " + accesstoken
    });
    return this.http.delete<any>(this.baseUrl + data.api.dashoard.transmissionline.transmissionline + '/' + transmisionid, { headers: tokenheaders })
  }


  fetchCircles() {
    var accesstoken: any;
    accesstoken = sessionStorage.getItem("token")
    var tokenheaders = new HttpHeaders({
      Authorization: "Bearer " + accesstoken
    });
    return this.http.get<any>(this.baseUrl + data.api.dashoard.transmissionline.fetchCircles, { headers: tokenheaders })
  }

  fetchDivisions() {
    var accesstoken: any;
    accesstoken = sessionStorage.getItem("token")
    var tokenheaders = new HttpHeaders({
      Authorization: "Bearer " + accesstoken
    });
    return this.http.get<any>(this.baseUrl + data.api.dashoard.transmissionline.fetchDivisions, { headers: tokenheaders })
  }

  fetchUserName() {
    var accesstoken: any;
    accesstoken = sessionStorage.getItem("token")
    var tokenheaders = new HttpHeaders({
      Authorization: "Bearer " + accesstoken
    });
    return this.http.get<any>(this.baseUrl + data.api.dashoard.transmissionline.fetchUsername, { headers: tokenheaders })
  }

  // .............................................................


  getEquipmentType() {
    var accesstoken: any;
    accesstoken = sessionStorage.getItem("token")
    var tokenheaders = new HttpHeaders({
      Authorization: "Bearer " + accesstoken
    });
    return this.http.get<any>(this.baseUrl + data.api.dashoard.equipmentType.equipmentType, { headers: tokenheaders });
  }
  getEquipmentType_isBay(is_bay: any) {
    var accesstoken: any;
    accesstoken = sessionStorage.getItem("token")
    var tokenheaders = new HttpHeaders({
      Authorization: "Bearer " + accesstoken
    });
    return this.http.get<any>(this.baseUrl + data.api.dashoard.equipmentType.equipmentType + '?is_bay_equipment=' + is_bay, { headers: tokenheaders });
  }

  editEquiupmentTypeName(putData: any) {
    var accesstoken: any;
    accesstoken = sessionStorage.getItem("token")
    var tokenheaders = new HttpHeaders({
      Authorization: "Bearer " + accesstoken
    });
    return this.http.put<any>(this.baseUrl + data.api.dashoard.equipmentType.editEquipmentType, putData, { headers: tokenheaders });
  }

  getEquipment() {
    var accesstoken: any;
    accesstoken = sessionStorage.getItem("token")
    var tokenheaders = new HttpHeaders({
      Authorization: "Bearer " + accesstoken
    });
    return this.http.get<any>(this.baseUrl + data.api.dashoard.equipments.equipments , { headers: tokenheaders });
  }

  getEquipmentById(equipment_type_id: any) {
    var accesstoken: any;
    accesstoken = sessionStorage.getItem("token")
    var tokenheaders = new HttpHeaders({
      Authorization: "Bearer " + accesstoken
    });
    return this.http.get<any>(this.baseUrl + data.api.dashoard.equipmentType.equipmentType + "/" + equipment_type_id, { headers: tokenheaders });
  }

  deleteEquipment(equipmentid: any) {
    var accesstoken: any;
    accesstoken = sessionStorage.getItem("token")
    var tokenheaders = new HttpHeaders({
      Authorization: "Bearer " + accesstoken
    });
    return this.http.delete<any>(this.baseUrl + data.api.dashoard.equipments.equipments + '/' + equipmentid, { headers: tokenheaders })
  }

  getEquipmentTypeDataById(equipmentid: any) {
    var accesstoken: any;
    accesstoken = sessionStorage.getItem("token")
    var tokenheaders = new HttpHeaders({
      Authorization: "Bearer " + accesstoken
    });
    return this.http.get<any>(this.baseUrl + data.api.dashoard.equipments.equipments + '/' + equipmentid, { headers: tokenheaders })
  }

  editEquipmentType(putData: any) {
    var accesstoken: any;
    accesstoken = sessionStorage.getItem("token")
    var tokenheaders = new HttpHeaders({
      Authorization: "Bearer " + accesstoken
    });
    return this.http.put<any>(this.baseUrl + data.api.dashoard.equipments.equipments, putData, { headers: tokenheaders });
  }


  submitEquipmentType(postdata: any) {
    var accesstoken: any;
    accesstoken = sessionStorage.getItem("token")
    var tokenheaders = new HttpHeaders({
      Authorization: "Bearer " + accesstoken
    });
    return this.http.post<any>(this.baseUrl + data.api.dashoard.equipments.equipments, postdata, { headers: tokenheaders });
  }

  equipmentAttribute(postdata: any) {
    var accesstoken: any;
    accesstoken = sessionStorage.getItem("token")
    var tokenheaders = new HttpHeaders({
      Authorization: "Bearer " + accesstoken
    });
    return this.http.post<any>(this.baseUrl + data.api.dashoard.equipmentAttribute.equipmentAttribute, postdata, { headers: tokenheaders });
  }

  deleteEquipmentAttribute(id: any) {
    var accesstoken: any;
    accesstoken = sessionStorage.getItem("token")
    var tokenheaders = new HttpHeaders({
      Authorization: "Bearer " + accesstoken
    });
    return this.http.delete<any>(this.baseUrl + data.api.dashoard.equipmentAttribute.equipmentAttribute + '?id=' + id, { headers: tokenheaders })
  }

  deleteEquipmentAttributeType(id: any, equ_id: any) {
    var accesstoken: any;
    accesstoken = sessionStorage.getItem("token")
    var tokenheaders = new HttpHeaders({
      Authorization: "Bearer " + accesstoken
    });
    return this.http.delete<any>(this.baseUrl + data.api.dashoard.equipmentAttribute.deleteAttribute + '?field_id=' + id + '&equipment_type_id=' + equ_id, { headers: tokenheaders })
  }


  getEquipmentTypeData(id: any) {
    var accesstoken: any;
    accesstoken = sessionStorage.getItem("token")
    var tokenheaders = new HttpHeaders({
      Authorization: "Bearer " + accesstoken
    });
    return this.http.get<any>(this.baseUrl + data.api.dashoard.equipmentTypeData.equipmentTypeData + '/' + id, { headers: tokenheaders })
  }

  getEquipmentTypeTable(sortBy:any='',sortOrder:any='') {
    var accesstoken: any;
    accesstoken = sessionStorage.getItem("token")
    var tokenheaders = new HttpHeaders({
      Authorization: "Bearer " + accesstoken
    });
    return this.http.get<any>(this.baseUrl + data.api.dashoard.equipmentTypeTable.equipmentTypeTable +'?sortBy='+sortBy+'&sortOrder='+sortOrder, { headers: tokenheaders });
  }

  viewEquipmentTypeTable(id: any) {
    var accesstoken: any;
    accesstoken = sessionStorage.getItem("token")
    var tokenheaders = new HttpHeaders({
      Authorization: "Bearer " + accesstoken
    });
    return this.http.get<any>(this.baseUrl + data.api.dashoard.equipmentTypeTable.equipmentTypeTable + '/' + "attributes" + '?equipment_type_id=' + id +"&viewmode=publicmode", { headers: tokenheaders });
  }

  bayEquipmentEdit(bayequid: any, equid: any) {
    var accesstoken: any;
    accesstoken = sessionStorage.getItem("token")
    var tokenheaders = new HttpHeaders({
      Authorization: "Bearer " + accesstoken
    });
    return this.http.get<any>(this.baseUrl + data.api.dashoard.bayEquipmentEdit.bayEquipmentEdit + '?bay_equipment_id=' + bayequid + '&equipment_type_id=' + equid, { headers: tokenheaders });
  }
  updatebayEquipmentEdit(putData: any) {
    var accesstoken: any;
    accesstoken = sessionStorage.getItem("token")
    var tokenheaders = new HttpHeaders({
      Authorization: "Bearer " + accesstoken
    });
    return this.http.put<any>(this.baseUrl + data.api.dashoard.bayEquipmentEdit.bayEquipmentEdit, putData, { headers: tokenheaders });

  }

  updateSubStationEquipmentEdit(putData: any) {
    var accesstoken: any;
    accesstoken = sessionStorage.getItem("token")
    var tokenheaders = new HttpHeaders({
      Authorization: "Bearer " + accesstoken
    });
    return this.http.put<any>(this.baseUrl + data.api.dashoard.substationEquipment.substationEquipmentdata, putData, { headers: tokenheaders });

  }

  availability(putData: any) {
    var accesstoken: any;
    accesstoken = sessionStorage.getItem("token")
    var tokenheaders = new HttpHeaders({
      Authorization: "Bearer " + accesstoken
    });
    return this.http.put<any>(this.baseUrl + data.api.dashoard.availability.availability, putData, { headers: tokenheaders });
  }

  submitEquipmentTypeTable(postdata: any) {
    var accesstoken: any;
    accesstoken = sessionStorage.getItem("token")
    var tokenheaders = new HttpHeaders({
      Authorization: "Bearer " + accesstoken
    });
    return this.http.post<any>(this.baseUrl + data.api.dashoard.equipmentTypeTable.equipmentTypeTable, postdata, { headers: tokenheaders });
  }

  // ..................parameters......................................
  addParameter(postData: any) {
    var accesstoken: any;
    accesstoken = sessionStorage.getItem("token")
    var tokenheaders = new HttpHeaders({
      Authorization: "Bearer " + accesstoken
    });
    return this.http.post(this.baseUrl + data.api.dashoard.parameters.parameters, postData, { headers: tokenheaders });
  }

  addFrequency(postData: any) {
    var accesstoken: any;
    accesstoken = sessionStorage.getItem("token")
    var tokenheaders = new HttpHeaders({
      Authorization: "Bearer " + accesstoken
    });
    return this.http.post(this.baseUrl + data.api.dashoard.parameters.frequency, postData, { headers: tokenheaders });
  }

  fetchParameters() {
    var accesstoken: any;
    accesstoken = sessionStorage.getItem("token")
    var tokenheaders = new HttpHeaders({
      Authorization: "Bearer " + accesstoken
    });
    return this.http.get<any>(this.baseUrl + data.api.dashoard.parameters.parameters, { headers: tokenheaders })
  }

  fetchFrequency() {
    var accesstoken: any;
    accesstoken = sessionStorage.getItem("token")
    var tokenheaders = new HttpHeaders({
      Authorization: "Bearer " + accesstoken
    });
    return this.http.get<any>(this.baseUrl + data.api.dashoard.parameters.frequency, { headers: tokenheaders })
  }

  deleteParameter(id: any) {
    var accesstoken: any;
    accesstoken = sessionStorage.getItem("token")
    var tokenheaders = new HttpHeaders({
      Authorization: "Bearer " + accesstoken
    });
    return this.http.delete<any>(this.baseUrl + data.api.dashoard.parameters.parameters + '?id=' + id, { headers: tokenheaders })
  }

  fetchParameterMapping() {
    var accesstoken: any;
    accesstoken = sessionStorage.getItem("token")
    var tokenheaders = new HttpHeaders({
      Authorization: "Bearer " + accesstoken
    });
    return this.http.get<any>(this.baseUrl + data.api.dashoard.parameters.parameterMapping, { headers: tokenheaders })
  }

  fetchEquipmentParameterMapping() {
    var accesstoken: any;
    accesstoken = sessionStorage.getItem("token")
    var tokenheaders = new HttpHeaders({
      Authorization: "Bearer " + accesstoken
    });
    return this.http.get<any>(this.baseUrl + data.api.dashoard.parameters.EquipmentParameyterMapping, { headers: tokenheaders })
  }

  fetchEquipmentParameterMappingbyID(id: any) {
    var accesstoken: any;
    accesstoken = sessionStorage.getItem("token")
    var tokenheaders = new HttpHeaders({
      Authorization: "Bearer " + accesstoken
    });
    return this.http.get<any>(this.baseUrl + data.api.dashoard.parameters.EquipmentParameyterMappingById + "?id=" + id, { headers: tokenheaders })
  }



  addEquipmentParameterMapping(postData: any) {
    var accesstoken: any;
    accesstoken = sessionStorage.getItem("token")
    var tokenheaders = new HttpHeaders({
      Authorization: "Bearer " + accesstoken
    });
    return this.http.post<any>(this.baseUrl + data.api.dashoard.parameters.EquipmentParameyterMapping, postData, { headers: tokenheaders })
  }

  updateEquipmentParameterMapping(updateData: any) {
    var accesstoken: any;
    accesstoken = sessionStorage.getItem("token")
    var tokenheaders = new HttpHeaders({
      Authorization: "Bearer " + accesstoken
    });
    return this.http.put<any>(this.baseUrl + data.api.dashoard.parameters.EquipmentParameyterMapping, updateData, { headers: tokenheaders })
  }

  deleteEquipmentParameterMapping(id: any) {
    var accesstoken: any;
    accesstoken = sessionStorage.getItem("token")
    var tokenheaders = new HttpHeaders({
      Authorization: "Bearer " + accesstoken
    });
    return this.http.delete<any>(this.baseUrl + data.api.dashoard.parameters.EquipmentParameyterMapping + '?id=' + id, { headers: tokenheaders })
  }


  deleteFrequency(id: any) {
    var accesstoken: any;
    accesstoken = sessionStorage.getItem("token")
    var tokenheaders = new HttpHeaders({
      Authorization: "Bearer " + accesstoken
    });
    return this.http.delete<any>(this.baseUrl + data.api.dashoard.parameters.frequency + '?id=' + id, { headers: tokenheaders })
  }


  addParameterMapping(postData: any) {
    var accesstoken: any;
    accesstoken = sessionStorage.getItem("token")
    var tokenheaders = new HttpHeaders({
      Authorization: "Bearer " + accesstoken
    });
    return this.http.post(this.baseUrl + data.api.dashoard.parameters.parameterMapping, postData, { headers: tokenheaders });
  }

  deleteParameterMapping(id: any) {
    var accesstoken: any;
    accesstoken = sessionStorage.getItem("token")
    var tokenheaders = new HttpHeaders({
      Authorization: "Bearer " + accesstoken
    });
    return this.http.delete<any>(this.baseUrl + data.api.dashoard.parameters.parameterMapping + '?id=' + id, { headers: tokenheaders })
  }

  // ...........................LOG....................................


  fetchLogData(sub_station_id: any, startDate: any, endDate: any) {
    var accesstoken: any;
    accesstoken = sessionStorage.getItem("token")
    var tokenheaders = new HttpHeaders({
      Authorization: "Bearer " + accesstoken
    });
    return this.http.get<any>(this.baseUrl + data.api.dashoard.logData.logData + '?startdate=' + startDate + "&enddate=" + endDate + "&substation_id=" + sub_station_id, { headers: tokenheaders })
  }

  fetchParamvalues(logDataid: any) {
    var accesstoken: any;
    accesstoken = sessionStorage.getItem("token")
    var tokenheaders = new HttpHeaders({
      Authorization: "Bearer " + accesstoken
    });
    return this.http.get<any>(this.baseUrl + data.api.dashoard.logData.paramvalues + '?id=' + logDataid, { headers: tokenheaders })
  }
  // .............................alarm..........................
  fetchAlarm(id: any) {
    var accesstoken: any;
    accesstoken = sessionStorage.getItem("token")
    var tokenheaders = new HttpHeaders({
      Authorization: "Bearer " + accesstoken
    });
    return this.http.get<any>(this.baseUrl + data.api.dashoard.alarm.alarm + '?bay_type_id=' + id, { headers: tokenheaders })
  }

  fetchBayAlarm(id: any) {
    var accesstoken: any;
    accesstoken = sessionStorage.getItem("token")
    var tokenheaders = new HttpHeaders({
      Authorization: "Bearer " + accesstoken
    });
    return this.http.get<any>(this.baseUrl + data.api.dashoard.alarm.bayAlarm + '?id=' + id, { headers: tokenheaders })
  }

  editBayAlarm(putData: any) {
    var accesstoken: any;
    accesstoken = sessionStorage.getItem("token")
    var tokenheaders = new HttpHeaders({
      Authorization: "Bearer " + accesstoken
    });
    return this.http.put<any>(this.baseUrl + data.api.dashoard.alarm.bayAlarm, putData, { headers: tokenheaders })
  }

  fetchAlarmById(bayAlarmId: any) {
    var accesstoken: any;
    accesstoken = sessionStorage.getItem("token")
    var tokenheaders = new HttpHeaders({
      Authorization: "Bearer " + accesstoken
    });
    return this.http.get<any>(this.baseUrl + data.api.dashoard.alarm.alarmById + '?id=' + bayAlarmId, { headers: tokenheaders })
  }

  addAlarm(postData: any) {
    var accesstoken: any;
    accesstoken = sessionStorage.getItem("token")
    var tokenheaders = new HttpHeaders({
      Authorization: "Bearer " + accesstoken
    });
    return this.http.post(this.baseUrl + data.api.dashoard.alarm.alarm, postData, { headers: tokenheaders });
  }

  editAlarm(putData: any) {
    var accesstoken: any;
    accesstoken = sessionStorage.getItem("token")
    var tokenheaders = new HttpHeaders({
      Authorization: "Bearer " + accesstoken
    });
    return this.http.put<any>(this.baseUrl + data.api.dashoard.alarm.alarm, putData, { headers: tokenheaders });
  }

  deleteAlarm(id: any) {
    var accesstoken: any;
    accesstoken = sessionStorage.getItem("token");
    var tokenheaders = new HttpHeaders({
      Authorization: "Bearer " + accesstoken
    });
    return this.http.delete<any>(this.baseUrl + data.api.dashoard.alarm.alarm + '?id=' + id, { headers: tokenheaders })
  }


  // ..........................indicator..............................
  fetchIndicator(id: any) {
    var accesstoken: any;
    accesstoken = sessionStorage.getItem("token")
    var tokenheaders = new HttpHeaders({
      Authorization: "Bearer " + accesstoken
    });
    return this.http.get<any>(this.baseUrl + data.api.dashoard.indicator.indicator + '?bay_type_id=' + id, { headers: tokenheaders })
  }

  fetchIndicatorById(alarmId: any) {
    var accesstoken: any;
    accesstoken = sessionStorage.getItem("token")
    var tokenheaders = new HttpHeaders({
      Authorization: "Bearer " + accesstoken
    });
    return this.http.get<any>(this.baseUrl + data.api.dashoard.indicator.indicatorById + '?id=' + alarmId, { headers: tokenheaders })
  }

  addIndicator(postData: any) {
    var accesstoken: any;
    accesstoken = sessionStorage.getItem("token")
    var tokenheaders = new HttpHeaders({
      Authorization: "Bearer " + accesstoken
    });
    return this.http.post(this.baseUrl + data.api.dashoard.indicator.indicator, postData, { headers: tokenheaders });
  }

  editIndicator(putData: any) {
    var accesstoken: any;
    accesstoken = sessionStorage.getItem("token")
    var tokenheaders = new HttpHeaders({
      Authorization: "Bearer " + accesstoken
    });
    return this.http.put<any>(this.baseUrl + data.api.dashoard.indicator.indicator, putData, { headers: tokenheaders });
  }

  deleteIndicator(id: any) {
    var accesstoken: any;
    accesstoken = sessionStorage.getItem("token");
    var tokenheaders = new HttpHeaders({
      Authorization: "Bearer " + accesstoken
    });
    return this.http.delete<any>(this.baseUrl + data.api.dashoard.indicator.indicator + '?id=' + id, { headers: tokenheaders })
  }


  // ..........................Events..............................
  fetchEvents(id: any) {
    var accesstoken: any;
    accesstoken = sessionStorage.getItem("token")
    var tokenheaders = new HttpHeaders({
      Authorization: "Bearer " + accesstoken
    });
    return this.http.get<any>(this.baseUrl + data.api.dashoard.events.events + '?substation_id=' + id, { headers: tokenheaders })
  }

  fetchEventsById(substation_id: any) {
    var accesstoken: any;
    accesstoken = sessionStorage.getItem("token")
    var tokenheaders = new HttpHeaders({
      Authorization: "Bearer " + accesstoken
    });
    return this.http.get<any>(this.baseUrl + data.api.dashoard.events.eventsById + '?substation_id=' + substation_id, { headers: tokenheaders })
  }

  addEvents(postData: any) {
    var accesstoken: any;
    accesstoken = sessionStorage.getItem("token")
    var tokenheaders = new HttpHeaders({
      Authorization: "Bearer " + accesstoken
    });
    return this.http.post(this.baseUrl + data.api.dashoard.events.events, postData, { headers: tokenheaders });
  }

  editEvents(putData: any) {
    var accesstoken: any;
    accesstoken = sessionStorage.getItem("token")
    var tokenheaders = new HttpHeaders({
      Authorization: "Bearer " + accesstoken
    });
    return this.http.put<any>(this.baseUrl + data.api.dashoard.events.events, putData, { headers: tokenheaders });
  }
  MP(putData: any) {
    var accesstoken: any;
    accesstoken = sessionStorage.getItem("token")
    var tokenheaders = new HttpHeaders({
      Authorization: "Bearer " + accesstoken
    });
    return this.http.put<any>(this.baseUrl + data.api.dashoard.events.mp, putData, { headers: tokenheaders });
  }

  deleteEvents(id: any) {
    var accesstoken: any;
    accesstoken = sessionStorage.getItem("token");
    var tokenheaders = new HttpHeaders({
      Authorization: "Bearer " + accesstoken
    });
    return this.http.delete<any>(this.baseUrl + data.api.dashoard.events.events + '?id=' + id, { headers: tokenheaders })
  }

  // ..............................BayIndicators.............................

  fetchBayIndicator(id: any) {
    var accesstoken: any;
    accesstoken = sessionStorage.getItem("token")
    var tokenheaders = new HttpHeaders({
      Authorization: "Bearer " + accesstoken
    });
    return this.http.get<any>(this.baseUrl + data.api.dashoard.indicator.bayIndicators + '?sub_station_id=' + id, { headers: tokenheaders })
  }

  fetchBayIndicatorById(alarmId: any) {
    var accesstoken: any;
    accesstoken = sessionStorage.getItem("token")
    var tokenheaders = new HttpHeaders({
      Authorization: "Bearer " + accesstoken
    });
    return this.http.get<any>(this.baseUrl + data.api.dashoard.indicator.bayIndicators + '?id=' + alarmId, { headers: tokenheaders })
  }

  addBayIndicator(postData: any) {
    var accesstoken: any;
    accesstoken = sessionStorage.getItem("token")
    var tokenheaders = new HttpHeaders({
      Authorization: "Bearer " + accesstoken
    });
    return this.http.post(this.baseUrl + data.api.dashoard.indicator.bayIndicators, postData, { headers: tokenheaders });
  }

  editBayIndicator(putData: any) {
    var accesstoken: any;
    accesstoken = sessionStorage.getItem("token")
    var tokenheaders = new HttpHeaders({
      Authorization: "Bearer " + accesstoken
    });
    return this.http.put<any>(this.baseUrl + data.api.dashoard.indicator.bayIndicators, putData, { headers: tokenheaders });
  }

  deleteBayIndicator(id: any) {
    var accesstoken: any;
    accesstoken = sessionStorage.getItem("token");
    var tokenheaders = new HttpHeaders({
      Authorization: "Bearer " + accesstoken
    });
    return this.http.delete<any>(this.baseUrl + data.api.dashoard.indicator.bayIndicators + '?id=' + id, { headers: tokenheaders })
  }


  // ...........................Roaster.........................................


  fetchRoaster(id: any, status: any) {
    var accesstoken: any;
    accesstoken = sessionStorage.getItem("token")
    var tokenheaders = new HttpHeaders({
      Authorization: "Bearer " + accesstoken
    });
    return this.http.get<any>(this.baseUrl + data.api.dashoard.roaster.roaster + '?substation_id=' + id + "&roster_status=" + status, { headers: tokenheaders })
  }



  addRoaster(postData: any) {
    var accesstoken: any;
    accesstoken = sessionStorage.getItem("token")
    var tokenheaders = new HttpHeaders({
      Authorization: "Bearer " + accesstoken
    });
    return this.http.post(this.baseUrl + data.api.dashoard.roaster.roaster, postData, { headers: tokenheaders });
  }

  deleteRoaster(id: any) {
    var accesstoken: any;
    accesstoken = sessionStorage.getItem("token");
    var tokenheaders = new HttpHeaders({
      Authorization: "Bearer " + accesstoken
    });
    return this.http.delete<any>(this.baseUrl + data.api.dashoard.roaster.roaster + '?id=' + id, { headers: tokenheaders })
  }

  fetchRosterById(id: any) {
    var accesstoken: any;
    accesstoken = sessionStorage.getItem("token");
    var tokenheaders = new HttpHeaders({
      Authorization: "Bearer " + accesstoken
    });
    return this.http.get(this.baseUrl + data.api.dashoard.roaster.roaster + '/' + 'id' + '?id=' + id, { headers: tokenheaders })
  }

  editRoaster(putData: any) {
    var accesstoken: any;
    accesstoken = sessionStorage.getItem("token")
    var tokenheaders = new HttpHeaders({
      Authorization: "Bearer " + accesstoken
    });
    return this.http.put(this.baseUrl + data.api.dashoard.roaster.roaster, putData, { headers: tokenheaders });
  }

  sendToRosterVerify(id: any) {
    var accesstoken: any;
    accesstoken = sessionStorage.getItem("token")
    var tokenheaders = new HttpHeaders({
      Authorization: "Bearer " + accesstoken
    });
    return this.http.put(this.baseUrl + data.api.dashoard.roaster.sendVerify, id, { headers: tokenheaders });
  }
  FinalVerifyRoster(putdata: any) {
    var accesstoken: any;
    accesstoken = sessionStorage.getItem("token")
    var tokenheaders = new HttpHeaders({
      Authorization: "Bearer " + accesstoken
    });
    return this.http.put(this.baseUrl + data.api.dashoard.roaster.verify, putdata, { headers: tokenheaders });
  }

  DuplicateRoaster(putData: any) {
    var accesstoken: any;
    accesstoken = sessionStorage.getItem("token")
    var tokenheaders = new HttpHeaders({
      Authorization: "Bearer " + accesstoken
    });
    return this.http.post(this.baseUrl + data.api.dashoard.roaster.roaster, putData, { headers: tokenheaders });
  }

  // ............................................................................
  getAttribute(page: any, limit: any) {
    // var accesstoken = sessionStorage.getItem("token")
    // var  tokenheaders = new HttpHeaders({
    //   Authorization: `{accesstoken}`
    // });
    return this.http.get<any>(this.baseUrl + data.api.dashoard.attribute.attribute);
  }

  getAttributeField() {
    var accesstoken: any;
    accesstoken = sessionStorage.getItem("token")
    var tokenheaders = new HttpHeaders({
      Authorization: "Bearer " + accesstoken
    });
    return this.http.get<any>(this.baseUrl + data.api.dashoard.attributeFields.attributeFields, { headers: tokenheaders });
  }

  fetchtransmissionlineAttributes(TRANSMISSIONLINE:any) {
    var accesstoken: any;
    accesstoken = sessionStorage.getItem("token")
    var tokenheaders = new HttpHeaders({
      Authorization: "Bearer " + accesstoken
    });
    return this.http.get<any>(this.baseUrl + data.api.dashoard.transmissionlineAttributes.transmissionlineAttributes+'?group_type='+TRANSMISSIONLINE, { headers: tokenheaders });
  }
addtransmissionAttributes(postData:any){
  var accesstoken: any;
  accesstoken = sessionStorage.getItem("token")
  var tokenheaders = new HttpHeaders({
    Authorization: "Bearer " + accesstoken
  });
  return this.http.post<any>(this.baseUrl + data.api.dashoard.collectionAttribute.collectionAttribute+'?group_type=TRANSMISSIONLINE',postData, { headers: tokenheaders });
}


  missingAttrubuteFields(id: any) {
    var accesstoken: any;
    accesstoken = sessionStorage.getItem("token")
    var tokenheaders = new HttpHeaders({
      Authorization: "Bearer " + accesstoken
    });
    return this.http.get<any>(this.baseUrl + data.api.dashoard.missingAttrubuteFields.missingAttrubuteFields + '?equipment_type_id=' + id, { headers: tokenheaders });
  }

  addMissingFields(postData: any) {
    var accesstoken: any;
    accesstoken = sessionStorage.getItem("token")
    var tokenheaders = new HttpHeaders({
      Authorization: "Bearer " + accesstoken
    });
    return this.http.post(this.baseUrl + data.api.dashoard.missingAttrubuteFields.addMissingFields, postData, { headers: tokenheaders });
  }

  addAttributeField(postData: any) {
    var accesstoken: any;
    accesstoken = sessionStorage.getItem("token")
    var tokenheaders = new HttpHeaders({
      Authorization: "Bearer " + accesstoken
    });
    return this.http.post(this.baseUrl + data.api.dashoard.attributeFields.attributeFields, postData, { headers: tokenheaders });
  }


  addAttribute(postData: any) {
    //  var accesstoken = sessionStorage.getItem("token")
    //     var  tokenheaders = new HttpHeaders({
    //       Authorization: `{accesstoken}`
    //     });
    return this.http.post(this.baseUrl + data.api.dashoard.attribute.attribute, postData);
  }


  fetchAttributeById(attributeid: any) {
    // var accesstoken = sessionStorage.getItem("token")
    // var  tokenheaders = new HttpHeaders({
    //   Authorization: `{accesstoken}`
    // });
    return this.http.get<any>(this.baseUrl + data.api.dashoard.attribute.attribute + '/' + attributeid)
  }
  editAttribute(putData: any) {
    // var accesstoken = sessionStorage.getItem("token")
    // var  tokenheaders = new HttpHeaders({
    //   Authorization: `{accesstoken}`
    // });
    return this.http.put<any>(this.baseUrl + data.api.dashoard.attribute.attribute, putData);
  }

  deleteAttribute(attributeid: any) {
    // var accesstoken = sessionStorage.getItem("token")
    // var  tokenheaders = new HttpHeaders({
    //   Authorization: `{accesstoken}`
    // });
    return this.http.delete<any>(this.baseUrl + data.api.dashoard.attribute.attribute + '/' + attributeid)
  }

  deleteAttributeFields(id: any) {
    var accesstoken: any;
    accesstoken = sessionStorage.getItem("token")
    var tokenheaders = new HttpHeaders({
      Authorization: "Bearer " + accesstoken
    });
    return this.http.delete<any>(this.baseUrl + data.api.dashoard.attributeFields.attributeFields + '?id=' + id, { headers: tokenheaders })
  }

  editAttrubutrFields(field_id: any) {
    var accesstoken: any;
    accesstoken = sessionStorage.getItem("token")
    var tokenheaders = new HttpHeaders({
      Authorization: "Bearer " + accesstoken
    });
    return this.http.get<any>(this.baseUrl + data.api.dashoard.editattributesFields.editattributesFields + '?field_id=' + field_id, { headers: tokenheaders })
  }


  UpdateAttributeFields(putData: any) {
    var accesstoken: any;
    accesstoken = sessionStorage.getItem("token")
    var tokenheaders = new HttpHeaders({
      Authorization: "Bearer " + accesstoken
    });
    return this.http.put<any>(this.baseUrl + data.api.dashoard.editattributesFields.updateAttributeFields, putData, { headers: tokenheaders });
  }

  uploadZones(postData: any) {
    var accesstoken: any;
    accesstoken = sessionStorage.getItem("token")
    var tokenheaders = new HttpHeaders({    
      Authorization: "Bearer " + accesstoken
    });
    return this.http.post<any>(this.baseUrl + data.api.dashoard.ZoneImportAndExport.uploadZone, postData, { headers: tokenheaders });
  }

  ExportZones() {
    var accesstoken: any;
    accesstoken = sessionStorage.getItem("token")
    var tokenheaders = new HttpHeaders({    
      Authorization: "Bearer " + accesstoken
    });
    return this.http.get<any>(this.baseUrl + data.api.dashoard.ZoneImportAndExport.exportZone,  { headers: tokenheaders });
  }
  uploadCircle(postData: any) {
    var accesstoken: any;
    accesstoken = sessionStorage.getItem("token")
    var tokenheaders = new HttpHeaders({    
      Authorization: "Bearer " + accesstoken
    });
    return this.http.post<any>(this.baseUrl + data.api.dashoard.circleImportAndExport.circleImport, postData, { headers: tokenheaders });
  }

  ExportCircle() {
    var accesstoken: any;
    accesstoken = sessionStorage.getItem("token")
    var tokenheaders = new HttpHeaders({    
      Authorization: "Bearer " + accesstoken
    });
    return this.http.get<any>(this.baseUrl + data.api.dashoard.circleImportAndExport.exportCircle,  { headers: tokenheaders });
  }
  uploadDivision(postData: any) {
    var accesstoken: any;
    accesstoken = sessionStorage.getItem("token")
    var tokenheaders = new HttpHeaders({    
      Authorization: "Bearer " + accesstoken
    });
    return this.http.post<any>(this.baseUrl + data.api.dashoard.divisionExportAndImport.divisionImport, postData, { headers: tokenheaders });
  }

  ExportDivision() {
    var accesstoken: any;
    accesstoken = sessionStorage.getItem("token")
    var tokenheaders = new HttpHeaders({    
      Authorization: "Bearer " + accesstoken
    });
    return this.http.get<any>(this.baseUrl + data.api.dashoard.divisionExportAndImport.divisionExport,  { headers: tokenheaders });
  }
  uploadSubStation(postData: any) {
    var accesstoken: any;
    accesstoken = sessionStorage.getItem("token")
    var tokenheaders = new HttpHeaders({    
      Authorization: "Bearer " + accesstoken
    });
    return this.http.post<any>(this.baseUrl + data.api.dashoard.subStationImportAndExport.subStationImport, postData, { headers: tokenheaders });
  }

  ExportSubStation() {
    var accesstoken: any;
    accesstoken = sessionStorage.getItem("token")
    var tokenheaders = new HttpHeaders({    
      Authorization: "Bearer " + accesstoken
    });
    return this.http.get<any>(this.baseUrl + data.api.dashoard.subStationImportAndExport.subStationExport,  { headers: tokenheaders });
  }
  uploadTransmission(postData: any) {
    var accesstoken: any;
    accesstoken = sessionStorage.getItem("token")
    var tokenheaders = new HttpHeaders({    
      Authorization: "Bearer " + accesstoken
    });
    return this.http.post<any>(this.baseUrl + data.api.dashoard.transmissionImportAndExport.transmissionImport, postData, { headers: tokenheaders });
  }
  ExportTransmission() {
    var accesstoken: any;
    accesstoken = sessionStorage.getItem("token")
    var tokenheaders = new HttpHeaders({    
      Authorization: "Bearer " + accesstoken
    });
    return this.http.get<any>(this.baseUrl + data.api.dashoard.transmissionImportAndExport.transmissionExport,  { headers: tokenheaders });
  }
  ExportTowers() {
    var accesstoken: any;
    accesstoken = sessionStorage.getItem("token")
    var tokenheaders = new HttpHeaders({    
      Authorization: "Bearer " + accesstoken
    });
    return this.http.get<any>(this.baseUrl + data.api.dashoard.towerImportAndExport.towerExport,  { headers: tokenheaders });
  }
  towerReadyToVerification(postData:any){
    var accesstoken: any;
    accesstoken = sessionStorage.getItem("token")
    var tokenheaders = new HttpHeaders({
      Authorization: "Bearer " + accesstoken
    });
    return this.http.put<any>(this.baseUrl+data.api.dashoard.towerVerification.towerReadyToVerify,postData,  { headers: tokenheaders });
  }
  getlov(){
    var accesstoken: any;
    accesstoken = sessionStorage.getItem("token")
    var tokenheaders = new HttpHeaders({
    Authorization: "Bearer " + accesstoken
    });
    return this.http.get<any>(this.baseUrl + data.api.dashoard.lovdata.lov, {headers: tokenheaders} )
  }
  getenumlov(){
    var accesstoken: any;
    accesstoken = sessionStorage.getItem("token")
    var tokenheaders = new HttpHeaders({
    Authorization: "Bearer " + accesstoken
    });
    return this.http.get<any>(this.baseUrl + data.api.dashoard.lovdata.enumlov, {headers: tokenheaders} )
  }
  createlov(postData: any) {
    var accesstoken: any;
    accesstoken = sessionStorage.getItem("token")
    var tokenheaders = new HttpHeaders({    
      Authorization: "Bearer " + accesstoken
    });
    return this.http.post<any>(this.baseUrl + data.api.dashoard.lovdata.createlov, postData, { headers: tokenheaders });
  }
  editlov(putdata: any){
    var accesstoken: any;
    accesstoken = sessionStorage.getItem("token")
    var tokenheaders = new HttpHeaders({
      Authorization: "Bearer " + accesstoken
    });
    return this.http.put<any>(this.baseUrl+data.api.dashoard.lovdata.editlov, putdata,{headers: tokenheaders});

  }

  deletelov(value_id: any) {
    var accesstoken: any;
    accesstoken = sessionStorage.getItem("token")
    var tokenheaders = new HttpHeaders({
      Authorization: "Bearer " + accesstoken
    });
    return this.http.delete<any>(this.baseUrl + data.api.dashoard.lovdata.deletelov + '?value_id=' +value_id, { headers: tokenheaders })
  }


  updatelov(putdata: any){
    var accesstoken: any;
    accesstoken = sessionStorage.getItem("token")
    var tokenheaders = new HttpHeaders({
      Authorization: "Bearer " + accesstoken
    });
    return this.http.put<any>(this.baseUrl+data.api.dashoard.updateenum.update, putdata,{headers: tokenheaders});

  }

  isAdmin(){
    return  localStorage.getItem('isAdmin')==='true';
  }

  downloadFile(fileUrl: string) {

    return this.http.get(fileUrl, { responseType: 'blob' });
  }
  gislocation(putdata:any){
    var accesstoken: any;
    accesstoken = sessionStorage.getItem("token")
    var tokenheaders = new HttpHeaders({
      Authorization: "Bearer " + accesstoken
    });
    return this.http.put<any>(this.baseUrl+data.api.dashoard.gislocation.update,putdata,{headers: tokenheaders});
  }
  clearTowerLocation(putdata:any){
    var accesstoken: any;
    accesstoken = sessionStorage.getItem("token")
    var tokenheaders = new HttpHeaders({
      Authorization: "Bearer " + accesstoken
    });
    return this.http.put<any>(this.baseUrl+data.api.dashoard.towers.location,putdata,{headers: tokenheaders});
  

  }

  getSubStationColumns(group_type:any) {
    var accesstoken: any;
    accesstoken = sessionStorage.getItem("token")
    var tokenheaders = new HttpHeaders({
      Authorization: "Bearer " + accesstoken
    });
    return this.http.get<any>(this.baseUrl+data.api.dashoard.sequence.sequencing+"?group_type="+group_type, { headers: tokenheaders });
  }
  getExportPDf(stage:any){
    var accesstoken: any;
    accesstoken = sessionStorage.getItem("token")
    var tokenheaders = new HttpHeaders({
      Authorization: "Bearer " + accesstoken
    });
    return this.http.get<any>(this.baseUrl+data.api.dashoard.pdf.exportpdf+"?stage="+stage, { headers: tokenheaders });
   
  }

  checkSubStationAssignment(substations: string){
    var accesstoken: any;
    accesstoken = sessionStorage.getItem("token")
    var tokenheaders = new HttpHeaders({
      Authorization: "Bearer " + accesstoken
    });
    return this.http.get<any>(this.baseUrl+data.api.dashoard.employee.substationhead+"?substationid="+substations,{headers: tokenheaders})
  }
}
