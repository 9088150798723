import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoginComponent } from './login/login.component';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { DemoMaterialModule } from '../demo-material-module';
import { PagenotfoundComponent } from './pagenotfound/pagenotfound.component';
import { OAuthCallbackComponent } from './o-auth-callback/o-auth-callback.component';
import { ChangepasswordComponent } from '../changepassword/changepassword.component';


@NgModule({
  declarations: [LoginComponent, PagenotfoundComponent, OAuthCallbackComponent],
  imports: [
    CommonModule,
    DemoMaterialModule, 
    FlexLayoutModule,
    FormsModule,
    ReactiveFormsModule,
  
  ]
})
export class AuthModule { }
