
    <div class="total">
      <div class="container">
        <mat-card>
          <mat-card-header>
            <div ><img class="center" src="assets/images/l&T/logonew.jpeg"></div>
            <!-- <mat-card-title style="text-align: center;">Login</mat-card-title> -->
          </mat-card-header>
          <mat-card-content>
            <form [formGroup]="PasswordForm">
              <!-- <mat-form-field>
                <mat-label>User Id</mat-label>
                <input matInput formControlName="userid" type="text" placeholder="Enter your User Id">
                <mat-error *ngIf="PasswordForm.controls['userid'].hasError('required')">Please enter your User Id</mat-error>
            </mat-form-field> -->
              <mat-form-field>
                  <mat-label>Current Password</mat-label>
                  <input matInput formControlName="currentpassword" type="password" placeholder="Enter your old Password">
                  <!-- <button mat-icon-button matSuffix (click)="togglePasswordVisibility()">
                    <mat-icon>{{ showPassword ? 'visibility' : 'visibility_off' }}</mat-icon>
                  </button> -->
                  
                  <mat-error *ngIf="PasswordForm.controls['currentpassword'].hasError('required')">Please enter the old
                      Password</mat-error>
                     
              </mat-form-field>
          
              <mat-form-field>
                  <mat-label>New Password</mat-label>
                  <input matInput formControlName="newpassword" type="{{ showPassword ? 'text' : 'password' }}" placeholder="Enter your New Password">
                  <button mat-icon-button matSuffix (click)="togglePasswordVisibility()">
                    <mat-icon>{{ showPassword ? 'visibility' : 'visibility_off' }}</mat-icon>
                  </button>
                  <mat-error *ngIf="PasswordForm.controls['newpassword'].hasError('required')">Please enter the New Password</mat-error>
                </mat-form-field>
          
              <mat-form-field>
                  <mat-label>Confirm New Password</mat-label>
                  <input matInput formControlName="confirmpassword" type="password" placeholder="Confirm new Password">
                  <!-- <button mat-icon-button matSuffix (click)="togglePasswordVisibility()">
                    <mat-icon>{{ showPassword ? 'visibility' : 'visibility_off' }}</mat-icon>
                    </button> -->
                  <mat-error *ngIf="PasswordForm.controls['confirmpassword'].hasError('required')">Please enter the confirm
                      Password</mat-error>
                      <mat-error *ngIf="PasswordForm.controls['confirmpassword'].hasError('mismatch')">Password does not match</mat-error>
          
              </mat-form-field>
          
              <button mat-raised-button color="primary"[disabled]="PasswordForm.invalid" (click)="OnSubmit(PasswordForm.value)">Submit</button>
            </form>
          </mat-card-content>
        </mat-card>
      </div>
      </div>
      
      