import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AuthApiService } from 'src/app/services/auth/auth-api.service';
import { LoginRequest } from 'src/app/models';
import Swal from 'sweetalert2';



@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  loginRequest: LoginRequest = {};
  loginForm!: FormGroup;
  emailPattern: any;
  hidePassword: boolean = true;
  loginButtonDisabled: boolean = false;
 


  constructor(
    private myfb: FormBuilder,
    private router: Router,
    private api: AuthApiService,
    private toster: ToastrService
  ) { }

  ngOnInit(): void {
    this.emailPattern = '^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$';
    this.loginForm = this.myfb.group({
      useremail: ['', [Validators.required]],
      password: ['', [Validators.required]]
    });

  }

  togglePasswordVisibility(): void {
    this.hidePassword = !this.hidePassword;
  }

  login(data: any) {
    this.loginButtonDisabled = true; // Disable the login button

    this.loginRequest.emailid = data.useremail;
    this.loginRequest.password = data.password;
    this.loginRequest.request_from = 'hvpnl_web';

    // Check if already logged in uncomment this to enable prevention of multi login 
    // if (localStorage.getItem('isLoggedin')) {
    //   const loggedInEmail = localStorage.getItem('emailid');
    //   if (loggedInEmail === data.useremail) {
    //     this.toster.error('You are already logged in.');
    //     this.loginButtonDisabled = false; // Enable the login button
    //     return;
    //   }
    // }
    
    if (localStorage.getItem('isLoggedin')) {
      
      this.router.navigate(['/zones']);
    }
    this.api.login(this.loginRequest).subscribe(
      (result: any) => {
        if (result.body.code == 200) {
          // Log in the user
          // Check if already logged in
          // if (localStorage.getItem('isLoggedin')) {
          //   this.toster.error('You are already logged in.');
          //   this.loginButtonDisabled = false; // Enable the login button
          //   return;
          // }
          // if (result.body.success.user_data.role_title == "Super Admin") {
          //   localStorage.setItem('isAdmin', 'true')
          // } else {
          //   localStorage.setItem('isAdmin', 'false');

          // }
          if(result.body.success.user_data.is_password_change_required.toUpperCase()==="YES"){
            sessionStorage.setItem('token', result.headers.get('Authorization'));
            this.router.navigate(['/password']);
            return;
          }
          if(result.body.success.user_data.role_slug.toUpperCase()==='SA'){
            localStorage.setItem('isAdmin', 'true');
          }else{
            localStorage.setItem('isAdmin', 'false');
          }
          
       
          localStorage.setItem('isLoggedin', 'true');
          localStorage.setItem('useraccesstoken', result.body.success.user_data.useraccesstoken);
          localStorage.setItem('userid', result.body.success.user_data.userid);
          localStorage.setItem('firstname', result.body.success.user_data.firstname);
          localStorage.setItem('lastname', result.body.success.user_data.lastname);
          localStorage.setItem('phonenumber', result.body.success.user_data.phonenumber);
          localStorage.setItem('emailid', result.body.success.user_data.emailid);
          localStorage.setItem('designation', result.body.success.user_data.designation);
          localStorage.setItem('department', result.body.success.user_data.department);
          localStorage.setItem('section', result.body.success.user_data.section);
          localStorage.setItem('role_id', result.body.success.user_data.role_id);
          sessionStorage.setItem('role_title', result.body.success.user_data.role_title);
          sessionStorage.setItem('token', result.headers.get('Authorization'));
          sessionStorage.setItem('routes', JSON.stringify(result.body.success.feature_list));

          var myMap = new Map<string, string>();
          for (let i = 0; i < result.body.success.feature_list.length; i++) {
            var featuretemp = JSON.stringify(result.body.success.feature_list[i]);
            myMap.set(result.body.success.feature_list[i].feature_name, featuretemp);
          }
          sessionStorage.setItem('feature_list', JSON.stringify(Array.from(myMap.entries())));

          this.router.navigate(['/' + result.body.success.feature_list[0].feature_route]);
          this.toster.success(result.body.message);
        }
      },
      (error) => {
        // this.toster.error(error.error.Message);
        Swal.fire("",error.error.Message,'error')
      }
    ).add(() => {
      this.loginButtonDisabled = false; // Enable the login button
    });
  }

    }
    
    

