import { Component, OnInit, TemplateRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { DashboardApiService } from '../services/auth/api services/dashboard-api.service';
import Swal from 'sweetalert2';


@Component({
  selector: 'app-changepassword',
  templateUrl: './changepassword.component.html',
  styleUrls: ['./changepassword.component.css']
})
export class ChangepasswordComponent implements OnInit {
  showPassword: boolean;
  PasswordForm: any;
  userId: string | null;
  currentPassword: any;
  originalPassword: any;
  // snackBar: any;
  // currentpassword: any;
  // password: any;
  


  togglePasswordVisibility() {
    this.showPassword = !this.showPassword;
  }
  constructor(private router: Router,  private formbuilder: FormBuilder, private api: DashboardApiService,private toster:ToastrService) {
    this.PasswordForm = this.formbuilder.group({
    // userId: ['',Validators.required],
      currentpassword: ['', Validators.required],
      newpassword: ['', Validators.required],
      confirmpassword: ['', Validators.required],
    }, {
      validator: this.passwordMatchValidator('newpassword', 'confirmpassword'),
    });
  }
  passwordMatchValidator(controlName: string, matchingControlName: string) {
    return (formGroup: FormGroup) => {
      const control = formGroup.controls[controlName];
      const matchingControl = formGroup.controls[matchingControlName];
      if (
        matchingControl.errors &&
        !matchingControl.errors.confirmedValidator
      ) {
        return;
      }
      if (control.value !== matchingControl.value) {
        matchingControl.setErrors({ confirmedValidator: true });
      } else {
        matchingControl.setErrors(null);
      }
    };

  }

  ngOnInit(): void {
  }
  OnSubmit(data: any) {
    
    
    if (this.PasswordForm.invalid) {
      // Show password mismatch error if the form is invalid
      this.PasswordForm.get('confirmpassword')?.setErrors({ mismatch: true });
      return;
    }
    // if(this.PasswordForm.invalid){
    //   this.toster.error("Unable to update password, Please try again later.")
    // }
    var postdata = {
      // 'userId':data.userid,
      // 'currentpassword': data.currentpassword,
      'newpassword': data.newpassword,
      'currentpassword':data.currentpassword
    }
    // this.router.navigate(['']);

    this.api.changePassword(postdata).subscribe((result: any) => {
      if (result.code === 200) {
         console.log(result);
        // this.toster.error(result.)
        
        this.PasswordForm.reset()
        this.toster.success(result.message);
        localStorage.clear();
        sessionStorage.clear();
        this.router.navigate([''])
      }
      },
    
     (error) => {
      // this.toster.error(error.error.Message);
      Swal.fire("",error.error.Message,'error')
     }
    )
    }}
    

  


