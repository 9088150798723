import { ChangeDetectorRef, Component, OnDestroy } from '@angular/core';
import { MediaMatcher } from '@angular/cdk/layout';
import { MenuItems } from '../../../shared/menu-items/menu-items';
import { DashboardApiService } from 'src/app/services/auth/api services/dashboard-api.service';
import { animate, state, style, transition, trigger } from '@angular/animations';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: [],
  animations: [
    trigger('arrowAnimation', [
      state('up', style({ transform: 'rotate(0deg)' })),
      state('down', style({ transform: 'rotate(180deg)' })),
      transition('up <=> down', animate('200ms ease-in-out'))
    ])
  ]
})
export class AppSidebarComponent implements OnDestroy {
  mobileQuery: MediaQueryList;
  private _mobileQueryListener: () => void;
  getFeature: any;
  roleid: any;
  getDetails: any;
  featureList: any;
  routes: any;
  sectionStates: { [key: string]: boolean } = {
    MDC: false,
    ODC: false,
    LDC: false,
    ONM: false,
    MEC: false
  };

  MDC: boolean = false;
  ODC: boolean = false;
  LDC: boolean = false;
  ONM: boolean = false;
  MEC: boolean = false;

  constructor(
    changeDetectorRef: ChangeDetectorRef,
    media: MediaMatcher,
    private api: DashboardApiService,
    public menuItems: MenuItems,
  ) {
    this.mobileQuery = media.matchMedia('(min-width: 768px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);
  }

  ngOnInit(): void {
    this.getFeatures();
    this.featureList = sessionStorage.getItem('routes');
    this.routes = JSON.parse(this.featureList);
    for (let i = 0; i <= this.routes.length; i++) {
      switch (this.routes[i].nesting) {
        case "Master Data":
          this.MDC = true
          break;
        case "Towers & Transmissionlines":
          this.MEC = true;
          break;
        case "User Management":
          this.ODC = true;
          break;
        case "Log Data":
          this.LDC = true;
          break;
        case "Operations & Maintenance":
          this.ONM =true;
          break;
      }
    }
   }

  toggleSection(section: string): void {
    Object.keys(this.sectionStates).forEach((key) => {
      if (key === section) {
        this.sectionStates[key] = !this.sectionStates[key];
      } else {
        this.sectionStates[key] = false;
      }
    });
  }

  isSectionOpen(section: string): boolean {
    return this.sectionStates[section];
  }

  getFeatures(): void {
    this.roleid = localStorage.getItem('role_id');
    this.api.fetchFeaturesById(this.roleid).subscribe((result: any) => {
      this.getFeature = result.success;
    });
  }

  ngOnDestroy(): void {
    this.mobileQuery.removeListener(this._mobileQueryListener);
  }
}
