import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dropdownSearch'
})
export class DropdownSearchPipe implements PipeTransform {

  transform(items: any[], searchTerm: string, selectedIds: number[]): any[] {
    if (!items || !searchTerm) {
      return items;
    }

    const filteredItems = items.filter(item => {
      const matchesSearch = item.substationname.toLowerCase().includes(searchTerm.toLowerCase());
      const isSelected = selectedIds.includes(item.substationid);
      return matchesSearch || isSelected;
    });

    return filteredItems;
  }


}

  