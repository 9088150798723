
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { LocationStrategy, PathLocationStrategy } from '@angular/common';
import { AppRoutes } from './app.routing';
import { AppComponent } from './app.component';

import { FlexLayoutModule } from '@angular/flex-layout';
import { FullComponent } from './layouts/full/full.component';
import { AppHeaderComponent } from './layouts/full/header/header.component';
import { AppSidebarComponent } from './layouts/full/sidebar/sidebar.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DemoMaterialModule } from './demo-material-module';

import { SharedModule } from './shared/shared.module';
import { SpinnerComponent } from './shared/spinner.component';
import { AuthModule } from './auth/auth.module';
import { ToastrModule } from 'ngx-toastr';
import { SearchOptionsPipe } from './searchPipe';
import { AgmCoreModule } from '@agm/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { StatusPipe } from './status.pipe';
import { ConstituencyComponent } from './material-component/constituency/constituency.component';
import { VoltageComponent } from './material-component/voltage/voltage.component';
// import { SubstationtypeComponent } from './material-component/substationtype/substationtype.component';
// import { SubstationownedbyComponent } from './material-component/substationownedby/substationownedby.component';
import { MatSortModule } from '@angular/material/sort';

// import { VerificationPipe } from './verification.pipe';
import { MAT_DIALOG_DEFAULT_OPTIONS } from '@angular/material/dialog';
import { ChangepasswordComponent } from './changepassword/changepassword.component';









@NgModule({
  declarations: [
    AppComponent,
    FullComponent,
    AppHeaderComponent,
    SpinnerComponent,
    AppSidebarComponent,
    SearchOptionsPipe,
    ChangepasswordComponent,
  
    
    
  
    // SubstationtypeComponent,
    // SubstationownedbyComponent,
    
  
   
   
   
    // VerificationPipe,




  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    DemoMaterialModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    HttpClientModule,
    SharedModule,
    AuthModule,
    MatDialogModule,
    FlexLayoutModule,
    MatExpansionModule,
    MatSortModule,
    
    ToastrModule.forRoot({
      positionClass: 'toast-top-right',
      timeOut: 2000
    }),

    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyCDEUJDexYM6fLKy3kBlp0I5QxwXAaQL2A',
      libraries: ['places']
    }),
    RouterModule.forRoot(AppRoutes)
  ],
  providers: [
    {
      provide: LocationStrategy,
      useClass: PathLocationStrategy
    },
    {
      provide: MAT_DIALOG_DEFAULT_OPTIONS,
      useValue: { disableClose: true, },
      
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
