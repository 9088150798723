import { Injectable } from '@angular/core';

export interface Menu {
  state: string;
  name: string;
  type: string;
  icon: string;
}

const MENUITEMS = [
  { state: 'roles', name: 'Roles', type: 'link', icon: 'av_timer' },
  { state: 'employees', name: 'Employees', type: 'link', icon: 'av_timer' },
  { state: 'zone', name: 'Zone', type: 'link', icon: 'av_timer' },
  { state: 'circle', name: 'Circle', type: 'link', icon: 'av_timer' },
  { state: 'division', name: 'Division', type: 'link', icon: 'av_timer' },
  { state: 'subDiv', name: 'Sub Division', type: 'link', icon: 'av_timer' },
  { state: 'subStation', name: 'Sub Station', type: 'link', icon: 'av_timer' },
  { state: 'trasmision', name: 'Transmision', type: 'link', icon: 'av_timer' },
  { state: 'bay', name: 'Bay Type Master', type: 'link', icon: 'av_timer' },
  { state: 'device', name: 'Devicess', type: 'link', icon: 'av_timer' },
  { state: 'equipment', name: 'Equipment', type: 'link', icon: 'av_timer' },
  // { state: 'attribute', name: 'Attribute', type: 'link', icon: 'av_timer' },
  // { state: 'ealm', name: 'Equipment Attribute link Master', type: 'link', icon: 'av_timer' },
  // { state: 'belm', name: 'Bay Equipment Link Master', type: 'link', icon: 'av_timer' },
  // { state: 'entryByUser', name: 'Entry by End User', type: 'link', icon: 'av_timer' },


  // ...........................

];

@Injectable()
export class MenuItems {
  getMenuitem(): Menu[] {
    return MENUITEMS;
  }
}
