// import { Routes } from '@angular/router';

// import { FullComponent } from './layouts/full/full.component';

// export const AppRoutes: Routes = [
  
//   {
//     path: '',
//     component: FullComponent,
//     children: [
//       {
//         path: '',
//         redirectTo: '/zone',
//         pathMatch: 'full'
//       },
//       {
//         path: '',
//         loadChildren:
//           () => import('./material-component/material.module').then(m => m.MaterialComponentsModule)
//       },
//       {
//         path: 'zone',
//         loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule)
//       }
//     ]
//   }
// ];

import { Routes } from '@angular/router';
import { LoginComponent } from './auth/login/login.component';
import { PagenotfoundComponent } from './auth/pagenotfound/pagenotfound.component';

import { FullComponent } from './layouts/full/full.component';
import { AuthService } from './shared/auth.service';
import { AuthgaurdGuard } from './shared/authgaurd.guard';
import { OAuthCallbackComponent } from './auth/o-auth-callback/o-auth-callback.component';
import { ChangepasswordComponent } from './changepassword/changepassword.component';

export const AppRoutes: Routes = [
  {
    path: 'auth',
    component: OAuthCallbackComponent
  },
  {
    path:'',
    component:LoginComponent
  },
  {
    path:'password',
    component:ChangepasswordComponent
  },
  {
    path: '',
    component: FullComponent,
    canActivate:[AuthgaurdGuard],
    children: [
      {
        path: 'zone',
        redirectTo: 'zone',
        pathMatch: 'full'
      },
      {
        path: '',
        loadChildren:
          () => import('./material-component/material.module').then(m => m.MaterialComponentsModule)
      },
      {
        path: 'zone',
        loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule)
      },
      
    ],
    
  },
  
  {
    path: '**',
    component: LoginComponent
  },
 
];
