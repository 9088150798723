<!-- ============================================================== -->
<!-- sidebar -->

<!-- ============================================================== -->

<!-- <mat-nav-list appAccordion>
    <mat-list-item appAccordionLink *ngFor="let menuitem of routes" routerLinkActive="selected" group="{{menuitem.feature_name }}">
        <a class="" appAccordionToggle [routerLink]="['/', menuitem.feature_route]">
            <span>{{ menuitem.feature_name | titlecase}}</span> 
            <span fxFlex></span> 
            <span class="label label-{{ badge.type }}" *ngFor="let badge of menuitem.badge">{{ badge.value }}</span> 
        </a>
    </mat-list-item>
</mat-nav-list> -->

<mat-nav-list>
  <div *ngIf="MDC">

  <mat-list-item>
    <a mat-list-item-link (click)="toggleSection('MDC')">
      <span mat-line>Master Data</span>
      <mat-icon [ngClass]="{'down': isSectionOpen('MDC'), 'up': !isSectionOpen('MDC')}" mat-list-icon>keyboard_arrow_down</mat-icon>
    </a>
  </mat-list-item>

  <ng-container *ngIf="isSectionOpen('MDC')">
    <mat-nav-list class="nested-list">
      <mat-list-item routerLinkActive="selected" *ngFor="let menuitem of routes" [routerLinkActive]="'selected'">
        <ng-container *ngIf="menuitem.nesting === 'Master Data'">
          <a class="" [routerLink]="['/', menuitem.feature_route]" *ngIf="menuitem.is_nav_access==='YES'">
            <span>{{ menuitem.feature_name | titlecase}}</span>
            <span fxFlex></span>
            <span class="label label-{{ badge.type }}" *ngFor="let badge of menuitem.badge">{{ badge.value }}</span>
          </a>
        </ng-container>
      </mat-list-item>
    </mat-nav-list>
  </ng-container>
</div>

<div *ngIf="MEC">
  <mat-list-item>
    <a mat-list-item-link (click)="toggleSection('MEC')">
      <span mat-line>Towers & Transmission Lines</span>
      <mat-icon [ngClass]="{'down': isSectionOpen('MEC'), 'up': !isSectionOpen('MEC')}" mat-list-icon>keyboard_arrow_down</mat-icon>
    </a>
  </mat-list-item>
  <ng-container *ngIf="isSectionOpen('MEC')">
    <mat-nav-list class="nested-list">
      <mat-list-item routerLinkActive="selected" *ngFor="let menuitem of routes" [routerLinkActive]="'selected'">
        <ng-container *ngIf="menuitem.nesting === 'Towers & Transmissionlines'">
          <a class="" [routerLink]="['/', menuitem.feature_route]" *ngIf="menuitem.is_nav_access==='YES'">
            <span>{{ menuitem.feature_name | titlecase}}</span>
            <span fxFlex></span>
            <span class="label label-{{ badge.type }}" *ngFor="let badge of menuitem.badge">{{ badge.value }}</span>
          </a>
        </ng-container>
      </mat-list-item>
    </mat-nav-list>
  </ng-container>
  </div>
  <div *ngIf="ODC">

  <mat-list-item>
    <a mat-list-item-link (click)="toggleSection('ODC')">
      <span mat-line>Operation Data</span>
      <mat-icon [ngClass]="{'down': isSectionOpen('ODC'), 'up': !isSectionOpen('ODC')}" mat-list-icon>keyboard_arrow_down</mat-icon>
    </a>
  </mat-list-item>

  <ng-container *ngIf="isSectionOpen('ODC')">
    <mat-nav-list class="nested-list">
      <mat-list-item routerLinkActive="selected" *ngFor="let menuitem of routes" [routerLinkActive]="'selected'">
        <ng-container *ngIf="menuitem.nesting === 'User Management'">
          <a class="" [routerLink]="['/', menuitem.feature_route]" *ngIf="menuitem.is_nav_access==='YES'">
            <span>{{ menuitem.feature_name | titlecase}}</span>
            <span fxFlex></span>
            <span class="label label-{{ badge.type }}" *ngFor="let badge of menuitem.badge">{{ badge.value }}</span>
          </a>
        </ng-container>
      </mat-list-item>
    </mat-nav-list>
  </ng-container>
</div>
<div *ngIf="LDC">

  <mat-list-item>
    <a mat-list-item-link (click)="toggleSection('LDC')">
      <span mat-line>Log Data</span>
      <mat-icon [ngClass]="{'down': isSectionOpen('LDC'), 'up': !isSectionOpen('LDC')}" mat-list-icon>keyboard_arrow_down</mat-icon>
    </a>
  </mat-list-item>

  <ng-container *ngIf="isSectionOpen('LDC')">
    <mat-nav-list class="nested-list">
      <mat-list-item routerLinkActive="selected" *ngFor="let menuitem of routes" [routerLinkActive]="'selected'">
        <ng-container *ngIf="menuitem.nesting === 'Log Data'">
          <a class="" [routerLink]="['/', menuitem.feature_route]" *ngIf="menuitem.is_nav_access==='YES'">
            <span>{{ menuitem.feature_name | titlecase}}</span>
            <span fxFlex></span>
            <span class="label label-{{ badge.type }}" *ngFor="let badge of menuitem.badge">{{ badge.value }}</span>
          </a>
        </ng-container>
      </mat-list-item>
    </mat-nav-list>
  </ng-container>
  </div>
<div *ngIf="ONM">
  <mat-list-item>
    <a mat-list-item-link (click)="toggleSection('ONM')">
      <span mat-line>Operations & Maintenance</span>
      <mat-icon [ngClass]="{'down': isSectionOpen('ONM'), 'up': !isSectionOpen('ONM')}" mat-list-icon>keyboard_arrow_down</mat-icon>
    </a>
  </mat-list-item>

  <ng-container *ngIf="isSectionOpen('ONM')">
    <mat-nav-list class="nested-list">
      <mat-list-item routerLinkActive="selected" *ngFor="let menuitem of routes" [routerLinkActive]="'selected'">
        <ng-container *ngIf="menuitem.nesting === 'Operations & Maintenance'">
          <a class="" [routerLink]="['/', menuitem.feature_route]" *ngIf="menuitem.is_nav_access==='YES'">
            <span>{{ menuitem.feature_name | titlecase}}</span>
            <span fxFlex></span>
            <span class="label label-{{ badge.type }}" *ngFor="let badge of menuitem.badge">{{ badge.value }}</span>
          </a>
        </ng-container>
      </mat-list-item>
    </mat-nav-list>
  </ng-container>
</div>
  <!-- <mat-list-item>
    <a mat-list-item-link (click)="toggleSection('')">
      <span mat-line>Other Section</span>
      <mat-icon [ngClass]="{'down': isSectionOpen(''), 'up': !isSectionOpen('')}" mat-list-icon>keyboard_arrow_down</mat-icon>
    </a>
  </mat-list-item> -->

  <ng-container >
    <mat-nav-list>
      <mat-list-item routerLinkActive="selected" *ngFor="let menuitem of routes" [routerLinkActive]="'selected'">
        <ng-container *ngIf="menuitem.nesting === ''">
          <a class="" (click)="toggleSection('')" [routerLink]="['/', menuitem.feature_route]" *ngIf="menuitem.is_nav_access==='YES'">
            <span>{{ menuitem.feature_name | titlecase}}</span>
            <span fxFlex></span>
            <span class="label label-{{ badge.type }}" *ngFor="let badge of menuitem.badge">{{ badge.value }}</span>
          </a>
        </ng-container>
      </mat-list-item>
    </mat-nav-list>
  </ng-container>
</mat-nav-list>

<style>
  .nested-list {
    background-color: wheat;
    padding: 15px;
    border-radius: 15px;
  }
  .down {
    transform: rotate(180deg);
  }
  .up {
    transform: rotate(0deg);
  }
</style>
