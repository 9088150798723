import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  constructor() { }
  token(){  
    return !!sessionStorage.getItem("token");  
    }  
}
