import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import data from 'src/assets/json/api.json'
@Injectable({
  providedIn: 'root'
})
export class AuthApiService {

  baseUrl = data.api.baseUrl;
  constructor(private http: HttpClient) { }

  login(postData: any){
      
      
     
    return this.http.post<any>(this.baseUrl+data.api.dashoard.login.login,postData,{observe: "response"});

  }
}