<!-- ============================================================== -->
<!-- Profile - style you can find in header.scss -->
<!-- ============================================================== -->
<button [matMenuTriggerFor]="profile" mat-icon-button class="m-r-5">
    <!-- <img src="assets/images/users/1.jpg" alt="user" class="profile-pic"> -->
    <div style="background: brown; border-radius:50%; ">
        <span style="color: #FFFF;"> <b>{{userIcon | uppercase}}</b></span>

    </div>
</button>
<mat-menu #profile="matMenu" class="mymegamenu">
    <!-- <button mat-menu-item>
        <mat-icon>settings</mat-icon> Settings </button> -->

    <button mat-menu-item>
        <mat-icon>person</mat-icon> {{name}} </button>

    <!-- <button mat-menu-item (click)="openDialog(myDialog)"> -->
        <button mat-menu-item (click)="openDialog()">
        <img style="height: 25px;" src="assets/images/l&T/icons8-password.svg" alt=""> Change Password </button>

    <button mat-menu-item>
        <img style="height: 25px;" src="assets/images/l&T/role.png"> {{role}} </button>
    <button (click)="logout()" mat-menu-item>
        <mat-icon>exit_to_app</mat-icon> Sign Out </button>
</mat-menu>

<!-- Dialog template -->
<ng-template #myDialog>
    <div class="row">
        <div style="display: flex; align-items: center; justify-content: space-between;">
            <h2 matDialogTitle>Change Password</h2>
            <span mat-icon-button aria-label="Example icon button with a heart icon" mat-dialog-close>
                <mat-icon>close</mat-icon>
            </span>
        </div>
    </div>
    

    <form [formGroup]="PasswordForm">
        <mat-form-field>
            <mat-label>Current Password</mat-label>
            <input matInput formControlName="currentpassword" type="password" placeholder="Enter your old Password">
            <mat-error *ngIf="PasswordForm.controls['currentpassword'].hasError('required')">Please enter the old
                Password</mat-error>
        </mat-form-field>

        <mat-form-field>
            <mat-label>New Password</mat-label>
            <input matInput formControlName="newpassword" type="{{ showPassword ? 'text' : 'password' }}" placeholder="Enter your New Password">
            <button mat-icon-button matSuffix (click)="togglePasswordVisibility()">
              <mat-icon>{{ showPassword ? 'visibility' : 'visibility_off' }}</mat-icon>
            </button>
            <mat-error *ngIf="PasswordForm.controls['newpassword'].hasError('required')">Please enter the New Password</mat-error>
          </mat-form-field>

        <mat-form-field>
            <mat-label>Confirm New Password</mat-label>
            <input matInput formControlName="confirmpassword" type="password" placeholder="Enter your New Password">
            <mat-error *ngIf="PasswordForm.controls['confirmpassword'].hasError('required')">Please enter the confirm
                Password</mat-error>
                <mat-error *ngIf="PasswordForm.controls['confirmpassword'].hasError('mismatch')">Password does not match</mat-error>

        </mat-form-field>

        <button mat-raised-button color="primary" [disabled]="PasswordForm.invalid" (click)="ChangePassword(PasswordForm.value, myDialog)">Submit</button>


    </form>
</ng-template>